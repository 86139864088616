import React, { Component } from "react"
import "../assets/css/Toolbar.css"

import { chatPath, chevronUpPath, configPath, endcallPath, helpPath, linkPath, microPath, microslashredfillPath, monitorPath, monitorslashPath, toolsPath, videoPath, videoslashredfillPath, volumePath } from "../assets/icons/helper"

import { connect } from "react-redux"

import { toggleAudio, toggleVideo, toggleScreenShare, restart } from "../redux/actions"
import useCheckBrowser from './hooks/useCheckBrowser'
import useMobile from './hooks/useMobile'

var isEqual = require("lodash.isequal")


export class Toolbar extends Component {
    constructor(props) {
        super(props)

        this.browser = {}
        this.state = {
            roomId: "",
            help: false,
            audioList: props.audioList,
            videoList: props.videoList,
            outputList: props.outputList,
            settings: props.settings,
            chat: props.chat,
            sharing: false,
            call: {
                isInActiveSession: false,
                startingCall: false,
                audio: true,
                video: true,
                screenShareSubscriber: null,
                screenSharePublisher: null,
            },

            showCopiedMessage: false,

            isInActivity: props.isInActivity,
            startingCall: props.startingCall,
            codes: props.codes,
            hasUnreadMessages: props.hasUnreadMessages,
            hasActivitiesInQueue: props.hasActivitiesInQueue,

            lastResync: 0,
        }
    }

    componentDidMount() {
        this.browser = useCheckBrowser()
    }

    componentWillUnmount() { }

    static getDerivedStateFromProps(nextProps, prevState) {
        const shouldUpdateChat = nextProps.chat !== prevState.chat
        const shouldUpdateSettings = nextProps.settings !== prevState.settings
        const shouldUpdateRoomId = nextProps.roomId !== prevState.roomId
        const shouldUpdateStartingCall = nextProps.startingCall !== prevState.startingCall
        const shouldUpdateNotification = nextProps.hasUnreadMessages !== prevState.hasUnreadMessages
        const shouldUpdateQueue = nextProps.queue !== prevState.queue
        const shouldUpdateQueueNotification = nextProps.hasActivitiesInQueue !== prevState.hasActivitiesInQueue
        const shouldUpdateAudioList = nextProps.audioList !== prevState.audioList
        const shouldUpdateVideoList = nextProps.videoList !== prevState.videoList
        const shouldUpdateCall = nextProps.call !== prevState.call

        if (shouldUpdateChat) {
            return { chat: nextProps.chat }
        } else if (shouldUpdateSettings) {
            return { settings: nextProps.settings }
        } else if (shouldUpdateRoomId) {
            return { roomId: nextProps.roomId }
        } else if (shouldUpdateStartingCall) {
            return { startingCall: nextProps.startingCall }
        } else if (shouldUpdateNotification) {
            return { hasUnreadMessages: nextProps.hasUnreadMessages }
        } else if (shouldUpdateQueue) {
            return { queue: nextProps.queue }
        } else if (shouldUpdateAudioList) {
            return { audioList: nextProps.audioList }
        } else if (shouldUpdateVideoList) {
            return { videoList: nextProps.videoList }
        } else if (shouldUpdateQueueNotification) {
            return { hasActivitiesInQueue: nextProps.hasActivitiesInQueue }
        } else if (shouldUpdateCall) {
            return { call: nextProps.call }
        } else {
            return null
        }
    }

    async componentDidUpdate(_prevProps, prevState) {
        const {
            chat,
            settings,
            startingCall,
            hasUnreadMessages,
            audioList,
            videoList,
            call
        } = this.props

        if (chat !== prevState.chat) {
            this.setState({ chat })
        }

        if (settings !== prevState.settings) {
            this.setState({ settings })
        }

        if (startingCall !== prevState.startingCall) {
            this.setState({ startingCall })
        }

        if (!isEqual(call, prevState.call)) {
            this.updateCurrentCallState(call)
        }

        if (hasUnreadMessages !== prevState.hasUnreadMessages) {
            this.setState({ hasUnreadMessages })
        }

        if (audioList !== prevState.audioList) {
            this.setState({ audioList })
        }

        if (videoList !== prevState.videoList) {
            this.setState({ videoList })
        }

    }

    // Listeners

    updateCurrentCallState(call) {
        this.setState({ call })
    }

    // toolbar functions

    async goHome() {
        this.props.goHome()
    }

    toggleAudio_() {
        const { dispatch } = this.props
        dispatch(toggleAudio())
    }

    toggleVideo_() {
        const { dispatch } = this.props
        dispatch(toggleVideo())
    }

    toggleAudioList() {
        this.props.toggleAudioList()
    }

    toggleVideoList() {
        this.props.toggleVideoList()
    }

    toggleOutputList() {
        this.props.toggleOutputList()
    }

    toggleScreenShare_() {
        const { dispatch } = this.props
        dispatch(toggleScreenShare())
    }

    toggleSettings() {
        this.setState({ settings: !this.state.settings })
        this.props.toggleSettingsModal()
    }

    toggleChat() {
        this.setState({ chat: !this.state.chat })
        this.props.toggleChat()
    }

    toggleHelp() {
        this.setState({ help: !this.state.help }, () => {
            if (this.state.help) window.openHelp()
            else window.closeHelp()
        })
    }

    async copyText(text) {
        await navigator.clipboard.writeText(text)
    }

    shareRoom() {
        const { roomId } = this.state
        let copyURL = `https://${window.location.hostname}/room/${roomId}`
        this.setState({ showCopiedMessage: true })

        this.copyText(copyURL)
        setTimeout(() => {
            this.setState({ showCopiedMessage: false })
        }, 2000)
    }

    openDocs() {
        // open URL in new tab
        window.open('https://between.co/dashboard', '_blank')
    }

    // UI Helpers

    renderCallButton(options) {

        /**

        FAIcon      icon        main icon for button
        FAIcon      iconAlt     alt icon for button
        boolean     condition   condition to use alt icon 
        string      text        main text for button
        string      textAlt     alt text for buttton for condition
        boolean     chevron     condition to have a chevron
        function    func        onpress for the button
        function    chevFunc    onpress for chevron
        boolean     badge       mainly for message icon
        */

        let icon = options.icon
        let iconAlt = options.iconAlt
        let condition = options.condition
        let text = options.text
        let textAlt = options.textAlt
        let chevron = options.chevron
        let func = options.func
        let chevFunc = options.chevFunc
        let badge = options.badge

        let popover = options.popover
        let popoverView = null
        if (popover) {
            popoverView = this.state.showCopiedMessage ? (
                <div id="copy-hover" className="copy-hover">
                    <p>Invite URL Copied!</p>
                </div>
            ) : null
        }

        return (
            <div className='toolbar-call-btn-container'>
                {popover ? popoverView : null}
                <div className={`toolbar-call-btn`} >
                    <div className='toolbar-call-btn-inner' onClick={func}>
                        <div className='toolbar-call-btn-child'>
                            <img
                                src={typeof condition === 'undefined' ? icon : condition ? icon : iconAlt}
                                className="toolbar-call-btn-icon"
                            />
                        </div>
                        <p>{typeof condition === 'undefined' ? text : condition ? text : textAlt}</p>
                    </div>
                    {badge ? <div className="notification-icon" /> : null}
                    {chevron ? <img src={chevronUpPath} onClick={chevFunc} className="toolbar-call-btn-chevron" /> : null}
                </div>
            </div>
        )

    }

    renderInviteButton() {

        return (
            <div onClick={this.openDocs.bind(this)} className="align-self-center mr-3">
                <button className="green-btn base-button invite-btn">
                    <img src={toolsPath} className="mr-2"/> Start Building!
                </button>
            </div>
        )
    }

    renderCallButtons() {
        const { startingCall, hasUnreadMessages, call: { screenSharePublisher, screenShareSubscriber, audio, video, isInActiveSession } } = this.state
        const { isInRoom } = this.props

        // join button or reconnect df isplays whether user is in a call while in the room
        if (isInRoom) {
            if (startingCall) {
                // render loading period
                return (
                    <div className="h-100 d-flex align-items-center justify-content-center">
                        <p className="mb-0 text-center text-white">Starting a call...</p>
                    </div>
                )
            } else if (isInActiveSession) {
                // render toolbar calls

                const audioOutputBtnOptions = {
                    icon: volumePath,
                    // iconAlt: volumeslashPath,
                    // condition: audio,
                    chevron: true,
                    text: 'Output',
                    // textAlt: 'Unmute',
                    chevFunc: this.toggleOutputList.bind(this),
                    func: null
                }
                // const audioOutputBtn = this.renderCallButton(audioOutputBtnOptions)

                const audioBtnOptions = {
                    icon: microPath,
                    iconAlt: microslashredfillPath,
                    condition: audio,
                    chevron: true,
                    text: 'Mute',
                    textAlt: 'Unmute',
                    chevFunc: this.toggleAudioList.bind(this),
                    func: this.toggleAudio_.bind(this)
                }
                const audioBtn = this.renderCallButton(audioBtnOptions)

                const videoBtnOptions = {
                    icon: videoPath,
                    iconAlt: videoslashredfillPath,
                    condition: video,
                    chevron: true,
                    text: 'Stop Video',
                    textAlt: 'Start Video',
                    chevFunc: this.toggleVideoList.bind(this),
                    func: this.toggleVideo_.bind(this)
                }
                const videoBtn = this.renderCallButton(videoBtnOptions)

                const screenShareBtnOptions = {
                    icon: monitorPath,
                    iconAlt: monitorslashPath,
                    condition: !screenSharePublisher || !screenShareSubscriber,
                    chevron: false,
                    text: 'Share Screen',
                    textAlt: 'Stop',
                    func: this.toggleScreenShare_.bind(this)
                }
                const screenShareBtn = this.renderCallButton(screenShareBtnOptions)

                const chatBtnOptions = {
                    icon: chatPath,
                    chevron: false,
                    text: 'Chat',
                    badge: hasUnreadMessages,
                    func: this.toggleChat.bind(this)
                }
                const chatBtn = this.renderCallButton(chatBtnOptions)

                const settingsBtnOptions = {
                    icon: configPath,
                    chevron: false,
                    text: 'Settings',
                    func: this.toggleSettings.bind(this)
                }
                const settingsBtn = this.renderCallButton(settingsBtnOptions)

                const helpBtnOptions = {
                    icon: helpPath,
                    chevron: false,
                    text: 'Help',
                    func: this.toggleHelp.bind(this)
                }
                const helpBtn = this.renderCallButton(helpBtnOptions)

                const shareBtnOptions = {
                    icon: linkPath,
                    chevron: false,
                    text: 'Share',
                    popover: true,
                    func: this.shareRoom.bind(this)
                }
                const shareBtn = this.renderCallButton(shareBtnOptions)

                return (
                    <div className="toolbar-grid toolbar-grid-center">
                        {/* {this.browser.name.toLowerCase() === 'chrome' ? audioOutputBtn : null} */}
                        {audioBtn}
                        {videoBtn}
                        {screenShareBtn}
                        {chatBtn}
                        {settingsBtn}
                        {shareBtn}
                    </div>
                )
            } else {
                // render pre-call chat/settings buttons

                const chatBtnOptions = {
                    icon: chatPath,
                    chevron: false,
                    text: 'Chat',
                    badge: hasUnreadMessages,
                    func: this.toggleChat.bind(this)
                }
                const chatBtn = this.renderCallButton(chatBtnOptions)

                const settingsBtnOptions = {
                    icon: configPath,
                    chevron: false,
                    text: 'Settings',
                    func: this.toggleSettings.bind(this)
                }
                const settingsBtn = this.renderCallButton(settingsBtnOptions)

                const shareBtnOptions = {
                    icon: linkPath,
                    chevron: false,
                    text: 'Share',
                    popover: true,
                    func: this.shareRoom.bind(this)
                }
                const shareBtn = this.renderCallButton(shareBtnOptions)

                return (
                    <div className="toolbar-grid toolbar-grid-center-before">
                        {chatBtn}
                        {settingsBtn}
                        {shareBtn}
                    </div>
                )
            }
        }
    }

    renderEndToolbar() {
        const { isInRoom } = this.props

        if (isInRoom) {
            return (
                <div id="endCallBtn" className={`align-self-center ${useMobile() ? "w-100" : ""}`}>
                    <div
                        onClick={this.goHome.bind(this)}
                        className={`base-button red-btn toolbar-end-call-btn`}
                    >
                        <div>
                            <img src={endcallPath} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        if (useMobile()) {
            return (
                <>
                    <div className="toolbar-bg">
                        <div className="col-12 d-flex flex-row justify-content-center align-items-center">
                            {this.renderEndToolbar()}
                        </div>
                    </div>
                </>
            )
        }

        return (
            <>
                <div className="toolbar-bg">
                    <div className="col-3 d-flex flex-row justify-content-start">
                        {this.renderInviteButton()}
                    </div>
                    <div className="col-6">{this.renderCallButtons()}</div>
                    <div className="col-3 d-flex flex-row justify-content-end">
                        {this.renderEndToolbar()}
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        call: state.call
    }
}

export default connect(mapStateToProps)(Toolbar)
