import React from "react"

function DebugView(props: any) {

    // default options
    const show = props.show
    if (!show) return null

    return (
        <div id="debugView" className="debugView">
            <p>SPIKE</p>
        </div>
    )
}

export default DebugView