import firebase from "../../firebase"
var database = firebase.database()

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_TOAST = "UPDATE_TOAST";

const updateNotification = (notify) => {
  return {
    type: UPDATE_NOTIFICATION,
    payload: notify
  };
};

const updateToast = (toast) => {
    return {
        type: UPDATE_TOAST,
        payload: toast
    }
}

export const notifyUser = (notify) => dispatch => {
  dispatch(updateNotification(notify))
}

const updateState = (type, payload) => {
    return {
        type,
        payload
    }
}

export const subToNotification = () => (dispatch, getState) => {
    database.ref(`/apiState`).on("value", (snap) => {
        if (snap.exists()) {
            
            const allNotifications = Object.values(snap.val().messages)

            const { notify } = getState()
            const existingNotifications = notify.appNotifications

            if (existingNotifications.length === 0) {
                // no already read notifications

                // filter out non active notifications
                const activeNotifications = allNotifications.filter((v) => v.active)

                dispatch(updateState(UPDATE_TOAST, activeNotifications))

            } else if (existingNotifications.length > 0) {
                // already read some notification
                // check which ones

                const newNotificationIds = allNotifications.map((v) => v.id)
                const existingNotificationIds = existingNotifications.map((v) => v.id)

                const newIds = newNotificationIds.filter((v) => !existingNotificationIds.includes(v))

                // filter out non active notifications
                const newNotifications = allNotifications.filter((v) => newIds.includes(v.id) && v.active)

                console.log('newNotifications', newNotifications)

                dispatch(updateState(UPDATE_TOAST, newNotifications))

            }
            
        }
    })
}