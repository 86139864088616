import * as React from "react"
import { connect } from "react-redux"
import firebase from "../firebase"
import moment from "moment"
import ReactGA from "react-ga"

import { notifyUser } from "../redux/actions"

import "../assets/css/ChatView.css"

import isEqual from "lodash.isequal"
import { closePath, rightarrowPath, trashPath } from "../assets/icons/helper"

const database = firebase.database()

type ChatProps = {
    type: string
    id: string
    uid: string
    displayName: string
    isShowing: boolean
    dispatch: (something) => void
    toggleChat: () => void
}

type ChatState = {
    message: string
    messages: Message[]
    isShowing: boolean
    lastReadMessageId: string
}

type Message = {
    id?: string
    messageText: string
    timestamp: number
    uid: string
    displayName: string
}

class Chat extends React.Component<ChatProps, ChatState> {
    state: ChatState = {
        isShowing: false,
        message: "",
        messages: [],
        lastReadMessageId: "",
    }

    private messagesEnd = React.createRef<HTMLDivElement>()
    private messageInput = React.createRef<HTMLInputElement>()

    componentDidMount() {
        this.startListenerForMessages()
        this.startListenerForLastRead()
    }

    componentWillUnmount() {
        const { type, id, uid } = this.props
        database.ref(`/${type}/${id}/messages`).off("value")
        database.ref(`/${type}/${id}/messages_notifications/${uid}`).off("value")
    }

    componentDidUpdate(_, prevState) {
        if (!isEqual(prevState.messages, this.state.messages)) {
            const node = this.messagesEnd.current
            node.scrollIntoView()
        }

        if (prevState.isShowing !== this.state.isShowing) {
            this.markLastMessageAsRead()

            if (this.state.isShowing) {
                setTimeout(() => { this.messageInput.current.focus() }, 250)
            } else {
                this.setState({ message: "" })
                setTimeout(() => { this.messageInput.current.blur() }, 250)
            }
        }
    }

    static getDerivedStateFromProps(props: ChatProps, state: ChatState): Partial<ChatState> | null {
        if (props.isShowing !== state.isShowing) {
            return { isShowing: props.isShowing }
        }

        return null
    }

    toggle() { this.props.toggleChat() }

    shouldNotifyUser(): { shouldNotify: boolean; lastMessageId: string } {
        const { messages, lastReadMessageId } = this.state

        let lastMessageId = ""
        if (messages.length > 1)
            lastMessageId = messages[messages.length - 1].id ? messages[messages.length - 1].id : ""
        else if (messages.length > 0) lastMessageId = messages[0].id ? messages[0].id : ""

        return {
            shouldNotify: lastMessageId && lastMessageId !== lastReadMessageId,
            lastMessageId,
        }
    }

    sendNotification() {
        const { shouldNotify } = this.shouldNotifyUser()

        const { dispatch } = this.props
        dispatch(notifyUser(shouldNotify))
    }

    markLastMessageAsRead() {
        const { type, id, uid } = this.props

        const { shouldNotify, lastMessageId } = this.shouldNotifyUser()

        if (shouldNotify) database.ref(`/${type}/${id}/messages_notifications/${uid}`).set(lastMessageId)
    }

    startListenerForLastRead() {
        const { type, id, uid } = this.props

        database.ref(`/${type}/${id}/messages_notifications/${uid}`).on("value", (snap) => {
            if (snap.exists()) {
                this.setState({ lastReadMessageId: snap.val() }, () => {
                    this.sendNotification()
                })
            }
        })
    }

    clearChat() {
        const { type, id } = this.props
        if (window.confirm("Are you sure you want to clear the chat?")) {
            database.ref(`/${type}/${id}/messages`).remove()
        }
    }

    startListenerForMessages() {
        const { type, id } = this.props

        database.ref(`/${type}/${id}/messages`).on("value", (snap) => {
            const { isShowing } = this.state

            if (snap.exists()) {
                const messages: Message[] = Object.values(snap.val())
                this.setState({ messages: messages }, () => {
                    if (isShowing) this.markLastMessageAsRead()
                    else this.sendNotification()
                })

                const node = this.messagesEnd.current!
                node.scrollIntoView()
            } else {
                this.setState({ messages: [] })
            }
        })
    }

    handleMessageChange(e: React.FormEvent<HTMLInputElement>) {
        this.setState({ message: e.currentTarget.value })
    }

    sendMessage(e: React.FormEvent<HTMLButtonElement>) {
        e.preventDefault()
        // validate message

        const { message } = this.state
        const { type, id, uid, displayName } = this.props

        if (message.length > 0) {
            database
                .ref(`/${type}/${id}/messages`)
                .push({
                    messageText: message,
                    timestamp: moment().valueOf(),
                    uid: uid,
                    displayName: displayName,
                })
                .then((snap) => {
                    const key = snap.key
                    database.ref(`/${type}/${id}/messages/${key}/id`).set(key)
                })

            // clear state variables
            this.setState({ message: "" })
        }
    }

    renderMessages() {
        const { messages } = this.state

        const views = []

        for (let i = 0; i < messages.length; i++) {
            const m = messages[i]

            const time = moment(m.timestamp)

            // make text before doing manipulations
            const text = time.isSame(moment(), "day") ? "Today" : time.format("dddd, MMMM Do")

            const fiveMinsAgo = time.subtract("5", "m").valueOf()
            const oneDayAgo = time.subtract("1", "d").valueOf()

            // default base props for ChatCell component
            let prevSameUid = false
            let passShortLimit = false
            let passLongLimit = false

            // create date header
            const dateHeader = <DateHeader key={`${m.timestamp}_header`} text={text} />

            if (i > 0 && messages.length > 1) {
                const prevMessage = messages[i - 1]

                prevSameUid = prevMessage.uid === m.uid
                passShortLimit = fiveMinsAgo >= prevMessage.timestamp
                passLongLimit = oneDayAgo >= prevMessage.timestamp

                if (passLongLimit) views.push(dateHeader)
            } else {
                views.push(dateHeader)
            }

            views.push(
                <ChatCell
                    key={`${m.timestamp}${m.uid}`}
                    message={m}
                    prevSameUid={prevSameUid}
                    passShortLimit={passShortLimit}
                    passLongLimit={passLongLimit}
                />
            )
        }

        return views
    }

    render() {
        const { message } = this.state
        const title = "Chat"

        return (
            <>
                <div className="chat-header">
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <h4 className="mb-0">{title}</h4>
                        <img className="chat-trash-button" src={trashPath} onClick={this.clearChat.bind(this)} />
                    </div>
                    <img className="chat-close-button" src={closePath} onClick={this.toggle.bind(this)} />
                </div>

                <div className="messages-feed">
                    {this.renderMessages()}
                    <div style={{ height: "1px" }} ref={this.messagesEnd} />
                </div>
                <form className="message-form" onSubmit={this.sendMessage.bind(this)}>
                    <input
                        id="chat-input"
                        ref={this.messageInput}
                        className="new-message"
                        placeholder="Type Something..."
                        type="text"
                        value={message}
                        onChange={this.handleMessageChange.bind(this)}
                    />
                    <div className="message-form-buttons">
                        <img className="send" src={rightarrowPath} onClick={this.sendMessage.bind(this)} />
                    </div>
                </form>
            </>
        )
    }
}

type ChatCellProps = {
    message: Message
    passLongLimit: boolean
    passShortLimit: boolean
    prevSameUid: boolean
}

class ChatCell extends React.Component<ChatCellProps, object> {

    renderText(messageText: string) {
        const URL_REGEX = /(?:(?:https?|ftp):\/\/|www\.)?[^\s/$.?#]+\.[^\s]+/gi
        return <span>{
            messageText.split(" ").map(part => {
                const isUrl = URL_REGEX.test(part)
                let redirectUrl = part;
                if (isUrl && !part.startsWith('http')) {
                    redirectUrl = `https://${part}`;
                }
                return isUrl ? <a key={part} href={redirectUrl} rel="noreferrer" target="_blank">{part}</a> : part + " "
            })
        }</span>
    }

    renderCell() {
        const { passLongLimit, passShortLimit, prevSameUid, message } = this.props

        let view = null
        const text = this.renderText(message.messageText)

        if (!passLongLimit && !passShortLimit && prevSameUid) {
            view = text
        } else if (!prevSameUid || passLongLimit || passShortLimit) {
            const fancyDate = moment(message.timestamp).format("h:mm a")
            view = (
                <>
                    <div className="d-flex flex-row justify-content-between">
                        <small className="m-0 font-weight-bold">
                            {message.displayName} <span className="washed-out">{fancyDate}</span>
                        </small>
                    </div>
                    {text}
                </>
            )
        }

        return (
            <div className="message-box">
                <div className="message-content">{view}</div>
            </div>
        )
    }

    render() {
        return this.renderCell()
    }
}

/* Message Header */

type HeaderProps = {
    text: string
}

const DateHeader = ({ text }: HeaderProps) => (
    <div className="date-header-container">
        <hr className="f-1" />
        <small>{text}</small>
        <hr className="f-1" />
    </div>
)

function mapStateToProps(state) {
    return {
        user: state.auth.user,
    }
}

const connector = connect(mapStateToProps)
export default connector(Chat)
