import React, { Component } from "react"

import ReactGA from "react-ga"

import { connect } from "react-redux"
import { Redirect, withRouter } from "react-router-dom"
import { loginGoogle, anonymousLogin } from "../redux/actions"

import pino from './pino'
import useCheckBrowser from './hooks/useCheckBrowser'
import useGetOs from './hooks/useGetOs'

import "../assets/css/App.css"
import Modal from "react-modal"

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            nameIfGuest: "",
            emailIfGuest: "",

            validEmail: false,
            enteringInfo: false,
        }
    }

    signIn = () => {
        const { dispatch } = this.props
        dispatch(loginGoogle())
    }

    // guest form handler

    startEnteringInfo() {
        this.setState({ enteringInfo: true })
    }

    handleEmail(e) {
        this.setState({
            emailIfGuest: e.target.value,
            validEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)
        })
    }

    submitInfo() {
        const { nameIfGuest } = this.state
        // check if info valid
        if (nameIfGuest.length >= 2 && nameIfGuest.length <= 26) {
            this.handleGuestLogin()
        }
    }

    handleGuestLogin() {
        const { nameIfGuest, emailIfGuest } = this.state
        const { dispatch } = this.props
        dispatch(anonymousLogin(nameIfGuest, emailIfGuest))
    }

    renderGuestModal() {
        const { enteringInfo, nameIfGuest } = this.state

        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                width: '40%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#E6EAEB',
                border: 'none',
            },
            overlay: {
                backgroundColor: 'rgba(0,0,0,0.5)'
            }
        };

        return (
            <Modal
                isOpen={enteringInfo}
                onRequestClose={() => this.setState({ enteringInfo: false })}
                style={customStyles}
                contentLabel="Guest Sign Up"
            >
                <div className="modal-container">
                    <h3>Guest Login</h3>
                    <div className="guest-input-container">
                        <p className="i-am-strong">Your Name</p>
                        <input className="base-input" name="nickname" id="guestName" isInvalid={nameIfGuest.length > 0 && (nameIfGuest.length < 2 || nameIfGuest.length > 26)} maxLength="26" type="text" onChange={(e) => this.setState({ nameIfGuest: e.target.value })} value={this.state.nameIfGuest} placeholder="Enter Your Name" />
                    </div>
                    <div className="action-container">
                        <button className="base-button secondary-btn" onClick={() => this.setState({ enteringInfo: false, nameIfGuest: "", emailIfGuest: "", validEmail: false })}>
                            <p>Cancel</p>
                        </button>
                        <button className="base-button purple-btn" id="contGuest" onClick={this.submitInfo.bind(this)}>
                            <p>Continue</p>
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }

    renderSignUpUI() {
        return (
            <div className="d-flex flex-column justify-content-center col-md-6 col-xs-12">
                <h1 className="onboarding-header">Welcome to <span className="accent-text">Between!</span></h1>
                <div className="mt-4">
                    <button className="login-button w-100" onClick={() => this.signIn()}>
                        <div>
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/1024px-Google_%22G%22_Logo.svg.png"
                                width="22"
                                height="22"
                                alt="logo"
                            />
                        </div>
                        Continue with Google
                    </button>
                </div>
                <button
                    className="login-button w-100 mt-4"
                    onClick={() => this.startEnteringInfo()}
                    id="guestBtn"
                >
                    <div>
                        <img
                            src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                            width="22"
                            height="22"
                            alt="logo"
                        />
                    </div>
                    Continue as Guest
                </button>

            </div>
        )
    }

    renderError() {
        const { loginError } = this.props

        const browser = useCheckBrowser()
        const os = useGetOs()

        const log = {
            browser,
            os,
            error: loginError,
            context: 'Error while logging in'
        }

        pino.error(log)

        var contentView = (
            <div>
                <p className="dark-text mb-0">Error Code: {loginError.error}</p>
                <p className="dark-text mb-0">Details: {loginError.details}</p>
            </div>
        )

        if (loginError.error === "idpiframe_initialization_failed") {
            // cookies are not enabled

            contentView = (
                <div>
                    <p className="dark-text mb-2">Error Code: {loginError.error}</p>
                    <p className="dark-text mb-0">
                        Cookies are not enabled in current environment, this can usually happen if you're in a private
                        window, switch to a normal window and try again!
                    </p>
                </div>
            )
        }

        return (
            <div className="main-wrapper">
                <div className="d-flex align-items-center h-100">
                    <div className="d-flex justify-content-center w-100">
                        <div className="field-holder p-5 w-50">
                            <h2 className="text-center dark-text">Error Logging In</h2>
                            <div className="mt-4">
                                <p className="dark-text mb-0 font-weight-bold mb-2">
                                    Please contact us at team@between.co with these details:
                                </p>
                                {contentView}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { loginError, isAuthenticated, location, isVerifying } = this.props
        console.log('location.state', location)
        if (loginError) {
            return this.renderError()
        } else if (isAuthenticated) {
            if (location.state) {
                return <Redirect exact from={"/"} to={location.state.from} />
            } else {
                return <Redirect exact from={"/"} to="/space" />
            }
        } else if (isVerifying) {
            return (
                <div className="main-wrapper">
                    <div className="d-flex align-items-center h-100">
                        <div className="d-flex justify-content-center w-100"></div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="main-wrapper-login-page">
                    <div className="container scroll-view-fixed" style={{ flex: 2, overflow: "hidden" }}>
                        <div className="d-flex justify-content-center align-items-center h-100 row">
                            {this.renderSignUpUI()}
                            {this.renderGuestModal()}
                        </div>
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        loginError: state.auth.loginError,
        isAuthenticated: state.auth.isAuthenticated,
        isVerifying: state.auth.isVerifying,
    }
}
export default withRouter(connect(mapStateToProps)(Login))
