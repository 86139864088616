/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useRef } from 'react';
import LoadingAnimation from "./LoadingAnimation"
import { microblackPath, videoblackPath } from "../assets/icons/helper";

const DeviceSelection = ({ browser, mobile, callback }) => {
    const [videoDevices, setVideoDevices] = useState([]);
    const [audioDevices, setAudioDevices] = useState([]);
    const [showPermModal, setShowPermModal] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const videoRef = useRef<HTMLVideoElement>();

    useEffect(() => {

        // check permission status here and set in state
        setLoading(true)

        if (browser === 'chrome') {
            // don't use permisions.query here, it's not supported in firefox
            // @ts-ignore
            navigator.permissions.query({ name: 'camera' }).then(result => {
                if (result.state === 'denied') setShowPermModal(true)
                else if (result.state === 'prompt') setShowInfoModal(true)
            })

            // @ts-ignore
            navigator.permissions.query({ name: 'microphone' }).then(result => {
                if (result.state === 'denied') setShowPermModal(true)
                else if (result.state === 'prompt') setShowInfoModal(true)
            })

        }

        checkPermissions()

    }, []);

    const checkPermissions = () => {
        if (browser === 'firefox' || browser === 'safari') setShowInfoModal(true)

        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(async (stream) => {
                setShowPermModal(false)
                setShowInfoModal(false)
                setLoading(false)

                await enumerateDevices();

                // stop streams after enumarating devices
                // otherwise labels on firefox will be empty
                stream.getTracks().forEach(track => track.stop());
            })
            .catch(err => {
                setShowPermModal(true)
                setLoading(false)
            });
    }

    const enumerateDevices = async () => {
        // Get available video and audio devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        const audioDevices = devices.filter(device => device.kind === 'audioinput');
        
        setVideoDevices(videoDevices);
        setAudioDevices(audioDevices);
        
        if (videoDevices.length > 0) { setVideoDevice(videoDevices[0].deviceId) }
        if (audioDevices.length > 0) { setAudioDevice(audioDevices[0].deviceId) }
    }

    const setVideoDevice = (deviceId: string) => {
        // remove old video track

        if (videoRef.current && videoRef.current.srcObject) {
            const src = videoRef.current.srcObject as MediaStream;
            const videoTrack = src.getVideoTracks()[0];
            src.removeTrack(videoTrack);
        }

        // add new video track
        navigator.mediaDevices.getUserMedia({ video: { deviceId: deviceId }, audio: false })
            .then(stream => {
                const videoTrack = stream.getVideoTracks()[0];
                if (videoRef.current) videoRef.current.srcObject = new MediaStream([videoTrack]);
            });
            
        
        // set in local storage
        window.localStorage.setItem('lastVideoDevice', deviceId);

    }

    const setAudioDevice = (deviceId: string) => {
        // set in local storage
        window.localStorage.setItem('lastAudioDevice', deviceId);
    }

    const handleVideoDeviceChange = (deviceId: string) => {
        setVideoDevice(deviceId);
    };

    const handleAudioDeviceChange = (deviceId: string) => {
        setAudioDevice(deviceId);
    };

    const handleJoinMeeting = () => {
        // check if audio/video permissions and devices are valid
        if (videoRef.current && videoRef.current.srcObject) {
            const src = videoRef.current.srcObject as MediaStream;
            src.getTracks().forEach(track => track.stop());
        }

        callback()
    }

    const tryAgain = () => {
        checkPermissions()
    }

    const renderPermissionsModal = () => {
        return (
            <div className={`device-selection-base ${mobile ? 'mobile-width' : ''}`}>
                <img className="img-fluid perm-image" src={require('../assets/chrome-enabled.png')}/>
                <div className='device-list-view'>
                    <h5>Enable Permissions</h5>
                    <p>To be in the call, you need to enable permissions so others can hear you.</p>
                    {
                        showPermModal ? <div onClick={() => tryAgain()} className="base-button purple-btn mt-3"><p>Try Again</p></div> : null
                    }
                </div>
            </div>
        )
    }

    const renderDeviceSelectionView = () => {
        return (
            <div className={`device-selection-base ${mobile ? 'mobile-width' : ''}`}>
                <video ref={videoRef} autoPlay playsInline muted />
                <div className='device-list-view'>
                    <div className='select-view'>
                        <img src={videoblackPath} className="mr-3"/>
                        <select className='device-list' onChange={e => handleVideoDeviceChange(e.target.value)}>
                            {videoDevices.map(device => <option key={device.deviceId} value={device.deviceId}>{device.label}</option>)}
                        </select>
                    </div>
                    <div className='select-view'>
                        <img src={microblackPath} className="mr-3"/>
                        <select className='device-list' onChange={e => handleAudioDeviceChange(e.target.value)}>
                            {audioDevices.map(device => <option key={device.deviceId} value={device.deviceId}>{device.label}</option>)}
                        </select>
                    </div>
                    <div className='base-button purple-btn mt-4' id="deviceJoinBtn" onClick={() => handleJoinMeeting()}><p>JOIN MEETING</p></div>
                </div>
            </div>
        )
    }

    const renderLoadingView = () => {
        return <div className={`device-selection-base ${mobile ? 'mobile-width' : ''}`}><LoadingAnimation small={true} /></div>
    }

    if (loading && !(showPermModal || showInfoModal)) return renderLoadingView()
    else if (showPermModal || showInfoModal) return renderPermissionsModal()
    else return renderDeviceSelectionView()
};

export default DeviceSelection;
