/* eslint-disable no-restricted-globals */
import UserModel from "../../models/UserModel"
import firebase from "../../firebase"
import useCheckBrowser from '../../components/hooks/useCheckBrowser'
import useGetOs from '../../components/hooks/useGetOs'
import pino from "../../components/pino"

import { BAError, BAErrorType, Conference, LocalParticipant, LocationType } from "between-api"

const database = firebase.database()
const browser = useCheckBrowser();

export const CLEAR_CALL = "CLEAR_CALL";
export const UPDATE_AUDIO_DEVICES = "UPDATE_AUDIO_DEVICES";
export const UPDATE_VIDEO_DEVICES = "UPDATE_VIDEO_DEVICES";
export const UPDATE_OUTPUT_DEVICES = "UPDATE_OUTPUT_DEVICES";
export const UPDATE_CURRENT_AUDIO = "UPDATE_CURRENT_AUDIO";
export const UPDATE_CURRENT_VIDEO = "UPDATE_CURRENT_VIDEO";
export const UPDATE_CURRENT_OUTPUT = "UPDATE_CURRENT_OUTPUT";
export const STARTING_CALL = "STARTING_CALL"
export const UPDATE_SESSION_HELPER = "UPDATE_SESSION_HELPER"
export const UPDATE_PUBLISHER = "UPDATE_PUBLISHER"
export const UPDATE_ACTIVE_SESSION = "UPDATE_ACTIVE_SESSION"
export const UPDATE_AUDIO = "UPDATE_AUDIO"
export const UPDATE_VIDEO = "UPDATE_VIDEO"
export const ADD_SUBSCRIBER = "ADD_SUBSCRIBER"
export const REMOVE_SUBSCRIBER = "REMOVE_SUBSCRIBER"
export const SUBSCRIBER_VIDEO_TOGGLE = "SUBSCRIBER_VIDEO_TOGGLE"
export const SUBSCRIBER_AUDIO_TOGGLE = "SUBSCRIBER_AUDIO_TOGGLE"
export const SUBSCRIBER_SCREENSHARE_TOGGLE = "SUBSCRIBER_SCREENSHARE_TOGGLE"
export const UPDATE_REMOTE = "UPDATE_REMOTE"
export const UPDATE_IN_OFFICE = "UPDATE_IN_OFFICE"
export const REMOVE_IN_OFFICE = "REMOVE_IN_OFFICE"
export const UPDATE_SCREEN_SHARE_SUBSCRIBER = "UPDATE_SCREEN_SHARE_SUBSCRIBER"
export const UPDATE_SCREEN_SHARE_PUBLISHER = "UPDATE_SCREEN_SHARE_PUBLISHER"
export const UPDATE_USER_LOCATION_TYPE = "UPDATE_USER_LOCATION_TYPE"
export const UPDATE_IN_OFFICE_MAIN_MIC = "UPDATE_IN_OFFICE_MAIN_MIC"
export const UPDATE_IN_OFFICE_MIC_LIST = "UPDATE_IN_OFFICE_MIC_LIST"
export const UPDATE_IN_OFFICE_LOCATIONS = "UPDATE_IN_OFFICE_LOCATIONS"
export const IS_CONNECTED = "IS_CONNECTED"
export const UPDATE_CALL_STATE = "UPDATE_CALL_STATE"
export const UPDATE_PERMISSION_STATUS = "UPDATE_PERMISSION_STATUS"
export const UPDATE_NETWORK_QUALITY = "UPDATE_NETWORK_QUALITY"
export const UPDATE_PREDICTED_LOCATION = "UPDATE_PREDICTED_LOCATION"
export const UPDATE_NOISE_REDUCTION = "UPDATE_NOISE_REDUCTION"

// dispatch functions for reducer
const clearCall = () => {
    return {
        type: CLEAR_CALL,
    }
}

const sendPublisher = (publisher) => {
    return {
        type: UPDATE_PUBLISHER,
        payload: publisher,
    }
}

const updateActiveSession = (activeSession) => {
    return {
        type: UPDATE_ACTIVE_SESSION,
        payload: activeSession,
    }
}

const updateState = (type, payload) => {
    return {
        type,
        payload
    }
}

// exported functions

let reconnectTimout;
let phone: Conference
let local: LocalParticipant

let _roomId = ""
let userId = ""

export function createConference(extraData: any, roomId: string) {
    return async (dispatch) => {
        let apiKey = "a74988b0-12b7-4682-8661-3699220b84e8"

        _roomId = roomId
        userId = extraData.userId
        
        phone = new Conference(apiKey, roomId, extraData)

        // set this listener here 
        // its going to dispatch automatically before call start if location onsite is predicted
        phone.on('predictedLocationType', (locationType: LocationType) => {
            dispatch(updateState(UPDATE_PREDICTED_LOCATION, locationType))
        })    

    }
}

// export spcific permission here
export const checkPermissions = () => async (dispatch, getState) => {
    let status = await phone.getPermissionState()

    dispatch(updateState(UPDATE_PERMISSION_STATUS, status))

    return status
}

export const setType = (userLocationType: LocationType) => (dispatch, getState) => { 
    if (userLocationType === LocationType.Onsite) dispatch(updateState(UPDATE_NOISE_REDUCTION, true))
    phone.setLocationType(userLocationType) 
}

export function setListenersAndStartCall() {
    return async (dispatch, getState) => {

        phone.on('connected', () => {
            dispatch(updateActiveSession(true))
            dispatch(updateState(UPDATE_CALL_STATE, "IN_CALL"))
        })

        phone.on("localParticipant", (lp) => {

            local = lp
            
            local.on("videoCreated", (video) => {
                dispatch(sendPublisher(video))

                safariAppendHelper(userId, video)

                const { call: { userLocationType }, room: { settings } } = getState()
                if (settings.muteParticipantsOnEntry && userLocationType === "offsite") {
                    setTimeout(() => {
                        dispatch(toggleAudio())
                    }, 400)
                }
            })

        })

        phone.on("peerJoined", (peer) => {

            let data = JSON.parse(peer.data.extraData)
            let id = data.userId

            const { call: { userLocationType } } = getState()

            // check if new peer is onsite
            let locationType = peer.data.location.locationType
            if (locationType === "onsite" && userLocationType === "onsite") {
                // push new onsite user dom element
                dispatch(updateState(UPDATE_IN_OFFICE, peer.data))
            }

            peer.on('videoCreated', (v: HTMLVideoElement) => {
                const newUser = new UserModel()
                newUser.setStreamManager(peer)
                newUser.setVideoElement(v)
                newUser.setType(locationType)
                newUser.setNickname(id)
                newUser.setUserId(id)
                dispatch(updateState(ADD_SUBSCRIBER, newUser))

                safariAppendHelper(id, v)

            })

            peer.on('videoPaused', () => { dispatch(updateState(SUBSCRIBER_VIDEO_TOGGLE, {id, active: false})) })
            peer.on('videoResumed', () => { dispatch(updateState(SUBSCRIBER_VIDEO_TOGGLE, {id, active: true})) })
            peer.on('audioPaused', () => { dispatch(updateState(SUBSCRIBER_AUDIO_TOGGLE, {id, active: false})) })
            peer.on('audioResumed', () => { dispatch(updateState(SUBSCRIBER_AUDIO_TOGGLE, {id, active: true})) })

        })

        phone.on("peerLeft", (peer) => {
            let data = JSON.parse(peer.data.extraData)
            let id = data.userId

            // check if new peer is onsite
            const { call: { userLocationType } } = getState()

            // check if new peer is onsite
            let locationType = peer.data.location.locationType
            if (locationType === "onsite" && userLocationType === "onsite") {
                // remove onsite peerId
                dispatch(updateState(REMOVE_IN_OFFICE, peer.data.peerId))
            }

            dispatch(updateState(REMOVE_SUBSCRIBER, id))
            safariRemoveHelper(id)
            // document.getElementById(id).remove()
        })

        phone.on("sessionTerminated", () => {
            dispatch(updateState(UPDATE_CALL_STATE, "ENDED"))
            
            database.ref(`/rooms/${_roomId}/who/${userId}`).remove()
            dispatch(clearCall())
            window.location.href = window.location.origin + '/space'

        })

        phone.on('devicesUpdated', (deviceInfo: any) => {
            // set devices here
            let devices = deviceInfo.devices
            let json = JSON.stringify(devices.map(d => {
                let obj = { deviceId: d.deviceId, label: d.label }
                return obj
            }))
            dispatch(peen('info', 'devices_list', json))
            dispatch(setDevices(deviceInfo))
        })

        // handle screenshare

        phone.on('localShareCreated', () => {
            dispatch(updateState(UPDATE_SCREEN_SHARE_PUBLISHER, true))
        })

        phone.on('localShareClosed', () => {
            dispatch(updateState(UPDATE_SCREEN_SHARE_PUBLISHER, null))
        })

        phone.on('screenShareCreated', (s) => {
            dispatch(updateState(SUBSCRIBER_SCREENSHARE_TOGGLE, {peerId: s.peerId, active: true}))
            dispatch(updateState(UPDATE_SCREEN_SHARE_SUBSCRIBER, s.video))
        })

        phone.on('screenShareClosed', (peerId: string) => {
            dispatch(updateState(SUBSCRIBER_SCREENSHARE_TOGGLE, {peerId, active: false}))
            dispatch(updateState(UPDATE_SCREEN_SHARE_SUBSCRIBER, null))
        })

        phone.on('error', (error: BAError) => {
            console.log('hpregr', phone.peerId)
            console.log('got error', error)
            if (!error.type) {
                dispatch(updateState("UPDATE_TOAST", [{
                    id: uuid(),
                    active: true,
                    type: 'error',
                    message: 'There was an error connecting to the server, rejoining the call momentarily.'
                }]))
                setTimeout(() => {
                    window.location.reload()
                }, 4000)
            } else if (error.type === "ERROR_INITIALIZING") {
                dispatch(updateState("UPDATE_TOAST", [{
                    id: uuid(),
                    active: true,
                    type: 'error',
                    message: 'Lost connection to server, rejoining the call momentarily.'
                }]))
                setTimeout(() => {
                    window.location.reload()
                }, 4000)
            } else {
                alert(error.message)
            }
        })

        // main mic listeners
        phone.on('onsiteCurrentMicrophoneUpdated', (peerId: string) => {
            dispatch(updateState(UPDATE_IN_OFFICE_MAIN_MIC, peerId))
        })

        phone.on('onsiteMicrophonesUpdated', (peerIds: string[]) => {
            dispatch(updateState(UPDATE_IN_OFFICE_MIC_LIST, peerIds))
        })

        phone.on('notification', (notification) => {
            let notif = [notification]
            dispatch(updateState("UPDATE_TOAST", notif))
        })

        phone.on('connectionQuality', (connection) => {
            // 'type' and 'message'
            dispatch(updateState("UPDATE_NETWORK_QUALITY", connection))
        })

        // start call
        try {
            await phone.startCall()
        } catch (error) {
            console.log('error', error)
        }

    }
}

export function startUserTypeListener(userId: string, roomId: string) {
    return async (dispatch) => {
        database.ref(`/rooms/${roomId}/who`).on("value", (snap) => {
            const who = snap.val()

            const remote = []

            for (var uid in who) {
                const type = who[uid].type
                if (type === "offsite") remote.push(uid)
            }

            if (who && who[userId]) {
                const currentType = who[userId].type.toLowerCase()
                dispatch(updateState(UPDATE_USER_LOCATION_TYPE, currentType))
                dispatch(updateState(UPDATE_REMOTE, remote))
            }
        })
    }
}

export function leaveSession(userId: string, home: boolean) {
    return async (dispatch, getState) => {

        const { call } = getState()
        if (call.callState === "ENDED") return

        if (phone && call.callState === "IN_CALL") {
            phone.endCall()
            phone = null
        } else {
            database.ref(`/rooms/${_roomId}/who/${userId}`).remove()
            dispatch(clearCall())

            if (home) window.location.href = window.location.origin + '/space'
            else window.location.reload()
        }
    }

}

export const toggleSpike = () => (dispatch, getState) => { phone.toggleSpikeDebug() }

// publisher helpers

export const toggleAudio = () => (dispatch, getState) => {
    // get state and uno reverse the bool
    const { call } = getState()
    const audio = !call.audio

    local.publishAudio(audio)
    dispatch(updateState(UPDATE_AUDIO, audio))
}

export const toggleVideo = () => (dispatch, getState) => {
    // get state and uno reverse the bool
    const { call } = getState()
    const video = !call.video

    local.publishVideo(video)
    dispatch(updateState(UPDATE_VIDEO, video))
}

export const toggleScreenShare = () => (dispatch, getState) => {
    const { call } = getState()

    const screenShareSubscriber = call.screenShareSubscriber
    const screenSharePublisher = call.screenSharePublisher

    if (screenSharePublisher) {
        dispatch(stopScreenShare())
    } else if (!screenSharePublisher && !screenShareSubscriber) {
        dispatch(startScreenshare())
    }
}

const startScreenshare = () => async (dispatch, getState) => {
    try {
        await phone.startScreenshare()
    } catch (error) {
        console.log("screenshare error", error)
    }
}

const stopScreenShare = () => async (dispatch, getState) => {
    try {
        await phone.stopScreenshare()
        dispatch(updateState(UPDATE_SCREEN_SHARE_PUBLISHER, null))
    } catch (error) {
        console.log("screenshare error", error)
    }
}

export const setNewCurrentAudioDevice = (deviceId: string) => dispatch => {
    if (local) local.setAudioSource(deviceId)
    dispatch(updateState(UPDATE_CURRENT_AUDIO, deviceId))
}

export const setNewCurrentVideoDevice = (deviceId: string) => dispatch => {
    if (local) local.setVideoSource(deviceId)
    dispatch(updateState(UPDATE_CURRENT_VIDEO, deviceId))
}

export const setNewCurrentOutputDevice = (deviceId: string) => dispatch => {
    // call to phone here
    phone.setOutputDevice(deviceId)
    dispatch(updateState(UPDATE_CURRENT_OUTPUT, deviceId))
}

function setDevices(deviceInfo: any) {

    return async (dispatch, getState) => {

        const { call: { currentAudioDevice, currentVideoDevice, currentOutputDevice } } = getState()

        let devices = deviceInfo.devices

        const audioDevices = devices.filter((d) => d.kind === "audioinput")
        const videoDevices = devices.filter((d) => d.kind === "videoinput")
        const outputDevices = devices.filter((d) => d.kind === "audiooutput")
        
        let newAudioDevice = deviceInfo.currentInput.deviceId
        let newVideoDevice = deviceInfo.currentVideo.deviceId
        let newOutputDevice = deviceInfo.currentOutput ? deviceInfo.currentOutput.id : ""

        let json = JSON.stringify({
            audio: newAudioDevice,
            video: newVideoDevice,
            output: newOutputDevice
        })
        dispatch(peen('info', 'setting_devices', json))

        dispatch(updateState(UPDATE_AUDIO_DEVICES, audioDevices))
        dispatch(updateState(UPDATE_VIDEO_DEVICES, videoDevices))
        dispatch(updateState(UPDATE_OUTPUT_DEVICES, outputDevices))
        dispatch(setNewCurrentAudioDevice(newAudioDevice))
        dispatch(setNewCurrentVideoDevice(newVideoDevice))
        if (browser.name.toLowerCase() === 'chrome') dispatch(setNewCurrentOutputDevice(newOutputDevice))

    }
}

function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

// error helper

const peen = (type: string, context, error) => (dispatch, getState) => {
    // type: error, info, warn

    const state = getState()
    const uid = state.auth.user.info.uid
    const os = useGetOs()

    const log = {
        browser,
        os,
        error,
        uid,
        context
    }


    if (type === 'error') pino.error(log)
    else if (type === 'info') pino.info(log)
    else if (type === 'warn') pino.warn(log)

}

const safariAppendHelper = (id: string, v: HTMLVideoElement) => {

    // set interval to check if dom element exists
    const interval = setInterval(() => {
        if (document.getElementById(`${id}_video-content`)) {
            document.getElementById(`${id}_video-content`).appendChild(v)
            // v.play().then(() => { console.log('played') }).catch((e) => { console.log('error', e) })
            clearInterval(interval)
        }
    }, 300)
}

const safariRemoveHelper = (id: string) => {
}

export const debugAudioState = (state: boolean) => (dispatch, getState) => {
    const { call } = getState()
    if (phone && call.callState === "IN_CALL") {
        phone.toggleNoiseReduction(state)
        dispatch(updateState(UPDATE_NOISE_REDUCTION, true))
    }
}