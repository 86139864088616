import * as React from "react"
import { connect } from "react-redux"
import firebase from "../firebase"
import ReactSwitch from "react-switch";
const Switch = ReactSwitch as any;

import { closePath } from "../assets/icons/helper"
import { updateMuteSetting } from "../redux/actions"

import "../assets/css/Profile.css"
import { debugAudioState } from "../redux/actions/call";

type UserProfile = {
    account_info: {
        has_visited: boolean
        type: string
    }
    info: firebase.User
}

type ProfileProps = {
    user: UserProfile
    settings: { muteParticipantsOnEntry: boolean }
    roomHost: string
    dispatch: (something) => void
    toggleProfile: () => void
    logUserOut: () => void
}

type ProfileState = {
    profile?: UserProfile
    settings: { muteParticipantsOnEntry: boolean }
    isHost: boolean
    checked: boolean
}

class Profile extends React.Component<ProfileProps, ProfileState> {
    state: ProfileState = {
        profile: null,
        settings: { muteParticipantsOnEntry: false },
        isHost: false,
        checked: false,
    }

    componentDidUpdate(prevProps: Readonly<ProfileProps>, prevState: Readonly<ProfileState>, snapshot?: any): void {
        if (this.props.settings.muteParticipantsOnEntry !== prevState.settings.muteParticipantsOnEntry) {
            this.setState({ settings: this.props.settings, checked: this.props.settings.muteParticipantsOnEntry })
        }

        const hasUid = this.props.user.info.uid.length !== 0
        const hasUpdatedState = prevState.profile !== null
        if (hasUid && !hasUpdatedState) {
            this.setState({ profile: this.props.user })
        }

        const isHost = this.props.roomHost === this.props.user.info.uid
        if (isHost !== prevState.isHost) {
            this.setState({ isHost })
        }
    }

    toggle() {
        this.props.toggleProfile()
    }

    logUserOut() {
        this.props.logUserOut()
    }

    handleChange = (checked: boolean) => {
        this.setState({ checked });
        this.props.dispatch(updateMuteSetting(checked))
    }

    render() {
        const { user } = this.props

        let name = ""
        let email = ""
        let imgUrl = ""
        if (user) {
            name = user.info.displayName
            email = user.info.email
            imgUrl = user.info.photoURL
        }

        return (
            <>
                <div className="chat-header">
                    <h4 className="mb-0">Settings</h4>
                    <img className="chat-close-button" src={closePath} onClick={this.toggle.bind(this)} />
                </div>
                {
                    this.state.isHost &&
                    <div className="call-settings">
                        <h5 className="mb-0">Room Settings</h5>
                        <label className="mute-switch">
                            <span>Mute Participants on Entry</span>
                            <Switch onChange={this.handleChange} checked={this.state.checked} checkedIcon={false} uncheckedIcon={false} />
                        </label>
                    </div>
                }
                <div className="call-settings">
                    <h5 className="mb-0">Account</h5>
                    <div className="profile-header">
                        <img src={imgUrl} alt="activityphoto" className="img-fluid profile-image" />
                        <h3 className="profile-name">{name}</h3>
                        <h6 className="profile-email">{email}</h6>
                    </div>
                </div>
                <div className="bottom-button-bar">
                    <button className="red-btn base-button" onClick={this.logUserOut.bind(this)}>
                        <p>Log Out</p>
                    </button>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        settings: state.room.settings,
        roomHost: state.room.host
    }
}

const connector = connect(mapStateToProps)
export default connector(Profile)
