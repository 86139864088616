import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from "react-redux"

import { infoPath } from "../assets/icons/helper"

function OnsiteThumbs(props: any) {

    const [users, setUsers] = useState([])
    const [hover, setHover] = useState(false)
    const [infoHover, setInfoHover] = useState(false)

    useEffect(() => {
        setUsers(props.call.inOfficeUsers)
    }, [props.call.inOfficeUsers])


    const renderThumbs = () => {
        const view = []

        users.forEach((data: any) => {
            // follows peer.data structure from call.ts
            view.push(<OnsiteThumb key={data.peerId} data={data} />)
        })

        return <div className={`thumb-holder`}>{view}</div>
    }

    const renderInfo = () => {
        const infoView = infoHover ? (
            <div className={`onsite-header ${infoHover ? 'thumb-hover-active' : ''}`}>
                <h5>Onsite Peers</h5>
                <p>People with you in the same room</p>
            </div>
        ) : null

        return (
            <div className='onsite-info'>
                {infoView}
                <img src={infoPath} onMouseOver={() => setInfoHover(true)} onMouseOut={() => setInfoHover(false)} />
            </div>
        )
    }

    if (users.length === 0) return null

    return (
        <div className={`onsite-thumb-container ${hover ? 'onsite-container-active' : ''}`} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            {renderInfo()}
            {renderThumbs()}
        </div>
    )
}

function OnsiteThumb(props: any) {

    const [hover, setHover] = useState(false)
    
    const data = props.data
    const extra = JSON.parse(data.extraData)
            
    // on hover of 'thumb' show name somehow
    const hoverView = hover ? (
        <div className={`thumb-hover ${hover ? 'thumb-hover-active' : ''}`}>
            <p>{extra.userName}</p>
        </div>
    ) : null

    return (
        <div key={data.peerId} className='onsite-thumb' onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            {hoverView}
            <img src={extra.photoURL} alt='avatar' />
        </div>
    )

}

function mapStateToProps(state: any) {
    return {
        user: state.auth.user,
        call: state.call,
    }
}

export default connect(mapStateToProps)(OnsiteThumbs)