import { UPDATE_NOTIFICATION, UPDATE_TOAST } from "../actions/notify";

const initialState = {
  hasUnreadMessages: false,
  appNotifications: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        hasUnreadMessages: action.payload,
      };
    case UPDATE_TOAST:
        return {
            ...state,
            appNotifications: action.payload
        }
    default:
      return state;
  }
};