import * as React from 'react';

import '../assets/css/LoadingAnimation.css';


export default function LoadingAnimation(props) {

    let spinnyboi;
    if (props.video) spinnyboi = require("../assets/spinner4.gif")
    else spinnyboi = require("../assets/spinner.gif")

    return (
        <div className={`loading-animation ${!props.small ? 'pos-abs' : ''}`}>
            <div className={`animation-container ${props.video ? 'animation-container-video' : ''} ${props.small ? 'animation-container-small' : ''}`}>
                <h1>{props.message}</h1>
                <img
                    className={`loading-spinner${props.video ? '-video' : ''}`}
                    src={spinnyboi}
                    alt={'loading'}
                />
            </div>
        </div>
    );
}