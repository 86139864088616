const env = window.location.hostname

let apiCall = ''
let googleCLientId = ''

if (env === 'localhost' || env === 'between-dev.web.app') {
    apiCall = 'https://us-central1-between-dev.cloudfunctions.net/api/'
    googleCLientId = '931717214545-fd4bd8gcfa1ut33oklo02t56uns9rsa5.apps.googleusercontent.com'
}
else if (env === 'in.between.co') {
    apiCall = 'https://us-central1-between-87295.cloudfunctions.net/api/'
    googleCLientId = '508176178355-r862ipr8kpq6k18p9gcqa7vrburpkfev.apps.googleusercontent.com'
}

apiCall = 'https://us-central1-between-dev.cloudfunctions.net/api/'
googleCLientId = '931717214545-fd4bd8gcfa1ut33oklo02t56uns9rsa5.apps.googleusercontent.com'

export {
    apiCall,
    googleCLientId
}