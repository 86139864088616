import firebase from "../../firebase"
import "firebase/compat/auth";
import 'firebase/compat/database';

import ReactGA from "react-ga"
import pino from "../../components/pino"

var database = firebase.database()

export const UPDATE_USER = "LOGIN_REQUEST"
export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const LOGOUT_REQUEST = "LOGOUT_REQUEST"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAILURE = "LOGOUT_FAILURE"
export const VERIFY_REQUEST = "VERIFY_REQUEST"
export const VERIFY_SUCCESS = "VERIFY_SUCCESS"
export const START_TRACKING = "START_TRACKING"
export const STOP_TRACKING = "STOP_TRACKING"

let nameIfGuest = ""
let emailIfGuest = ""

let isConferenceAccount = false
let confAccountDetails = {
    email: "",
    originalUid: ""
}

const requestLogin = () => {
    return {
        type: LOGIN_REQUEST,
    }
}

const receiveLogin = (user) => {
    return {
        type: LOGIN_SUCCESS,
        user,
    }
}

const loginError = (message) => {
    return {
        type: LOGIN_FAILURE,
        message,
    }
}

const requestLogout = () => {
    return {
        type: LOGOUT_REQUEST,
    }
}

const receiveLogout = () => {
    return {
        type: LOGOUT_SUCCESS,
    }
}

const logoutError = () => {
    return {
        type: LOGOUT_FAILURE,
    }
}

const verifyRequest = () => {
    return {
        type: VERIFY_REQUEST,
    }
}

const verifySuccess = () => {
    return {
        type: VERIFY_SUCCESS,
    }
}

const startTrackingUser = () => {
    return {
        type: START_TRACKING,
    }
}

const stopTrackingUser = () => {
    return {
        type: STOP_TRACKING,
    }
}

export const loginGoogle = () => async (dispatch) => {
    dispatch(requestLogin())
    try {
        const provider = new firebase.auth.GoogleAuthProvider()
        const result = await firebase.auth().signInWithPopup(provider)
        const user = result.user.toJSON()

        if (!user.phoneNumber) user.phoneNumber = ""
        if (!user.tenantId) user.tenantId = ""
        if (!user._redirectEventId) user._redirectEventId = ""

        const userSnap = await database.ref(`/users/${user.uid}`).once("value")
        if (userSnap.exists()) {
            let userInfo = userSnap.val()
            if (userInfo.account_info.type === "conference") {
                // create a random UID
                isConferenceAccount = true
                confAccountDetails = {
                    email: userInfo.info.email,
                    originalUid: userInfo.info.uid
                }
                dispatch(createAltAccount())
            }
        } else {
            const userToSend = {
                account_info: {
                    type: "free",
                    has_visited: false
                },
                info: user,
            }
            await database.ref(`/users/${user.uid}/`).set(userToSend)
            dispatch(receiveLogin(userToSend))
        }
    } catch (error) {
        dispatch(loginError(error.message))
    }
}

export const anonymousLogin = (name, email) => async (dispatch) => {
    dispatch(createAltAccount())

    // to use in verifyAuth listener
    nameIfGuest = name
    emailIfGuest = email
}

const createAltAccount = () => async (dispatch) => {
    try {
        await firebase.auth().signInAnonymously()
    } catch (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;

        if (errorCode === 'auth/operation-not-allowed') {
            alert('You must enable Anonymous auth in the Firebase Console.');
        } else {
            console.error(error);
        }
    }
}

export const logoutUser = () => async (dispatch) => {
    dispatch(requestLogout())
    try {
        await firebase.auth().signOut()
        dispatch(receiveLogout())
    } catch (error) {
        dispatch(logoutError())
    }
}

export const receiveError = (error, details) => async (dispatch) => {
    dispatch({ type: "LOGIN_FAILURE", payload: { error, details } })
}

export const verifyAuth = () => (dispatch) => {
    dispatch(verifyRequest())

    firebase.auth().onAuthStateChanged(async (user) => {
        if (user !== null) {
            const userSnap = await database.ref(`/users/${user.uid}`).once("value")
            if (userSnap.exists() && !isConferenceAccount) dispatch(receiveLogin(userSnap.val()))
            else {

                if (isConferenceAccount && user.isAnonymous) {
                    const userObj = {
                        account_info: {
                            has_visited: false,
                            is_authorized: true,
                            mainConferenceUid: confAccountDetails.originalUid,
                            type: "free"
                        },
                        info: {
                            displayName: "Conference Account",
                            email: confAccountDetails.email,
                            createdAt: Date.now(),
                            isAnonymous: true,
                            photoURL: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
                            uid: user.uid,
                        }
                    }
                    await database.ref(`/users/${user.uid}`).set(userObj)
                    await database.ref(`/users/${confAccountDetails.originalUid}/account_info/alt`).push(user.uid)
                    dispatch(receiveLogin(userObj))
                } else {
                    // if new user created is guest, fill in with temp info
                    if (user.isAnonymous) {
                        const userObj = {
                            account_info: {
                                has_visited: false,
                                is_authorized: true,
                                type: "free"
                            },
                            info: {
                                displayName: nameIfGuest.length > 0 ? nameIfGuest : "Guest User",
                                email: emailIfGuest.length > 0 ? emailIfGuest : "",
                                createdAt: Date.now(),
                                isAnonymous: true,
                                photoURL: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
                                uid: user.uid,
                            }
                        }

                        await database.ref(`/users/${user.uid}`).set(userObj)
                        dispatch(receiveLogin(userObj))
                    }
                }
            }
        }
        dispatch(verifySuccess())
    })
}

export const startTrackingSession = (id) => (dispatch) => {
    dispatch(startTrackingUser())

    ReactGA.initialize("UA-168851028-1", {
        debug: false,
        titleCase: false,
        gaOptions: {
            userId: id,
        },
    })
}

export const stopTrackingSession = () => (dispatch) => {
    dispatch(stopTrackingUser())
}

export const fetchUser = (id) => (dispatch) => {
    database.ref(`/users/${id}`).on("value", (snap) => {
        const user = snap.val()
        dispatch(receiveLogin(user))
    })
}

export const detachListener = (id) => (dispatch) => {
    database.ref(`/users/${id}`).off("value")
}
