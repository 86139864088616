import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Modal from "react-modal"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "../assets/css/LandingPage.css"
import "../assets/css/RoomPage.css"

import firebase from "../firebase"
import Toolbar from "./Toolbar"
import Chat from "./Chat"
import Profile from "./Profile"
import Dropdown from "./Dropdown"
import LoadingAnimation from "./LoadingAnimation"
import OnsiteThumbs from "./OnsiteThumbs"
import VideoGrid from "./VideoGrid"
import DebugView from "./DebugView"
import DeviceSelection from "./DeviceSelection"

import pino from "./pino"
import useCheckBrowser from './hooks/useCheckBrowser'
import useGetOs from './hooks/useGetOs'
import useMobile from './hooks/useMobile'
import { logoutUser, listenToRoom, detachRoomListener, detachListener, startUserTypeListener, leaveSession, toggleAudio, toggleVideo, toggleScreenShare, createConference, setType, setListenersAndStartCall, toggleSpike, debugAudioState } from "../redux/actions"
import { phonePath } from "../assets/icons/helper";

var database = firebase.database()
var isEqual = require("lodash.isequal")

class EphemeralRoomPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            roomCode: "",
            currentRoom: null,
            user: {},
            error: null,

            audioList: false,
            videoList: false,
            outputList: false,

            session: null,

            // toolbar buttons
            isShowingSettings: false,
            chat: false,
            notifications: {
                hasUnreadMessages: false,
                appNotifications: [],
            },

            // screenshare
            screenSharePublisher: null,
            screenShareSubscriber: null,

            showUnsupportedBrowser: false,
            showUnsupportedDeviceType: false,

            smallCells: false,

            choseDevices: false,
            call: {
                callState: "INITIAL", // INITIAL, IN_CALL, ENDED
                isInActiveSession: false,
                isConnected: false,

                inOfficeUsers: [],
                remoteUsers: [],
                userLocationType: "", // offsite, onsite
                predictedLocationType: "", // offsite, onsite

                inOfficeLocations: [],
                inOfficeMainMic: '',
                inOfficeMics: [],

                currentAudioDevice: "",
                currentVideoDevice: "",
                audioDevices: [],
                videoDevices: [],

                permissionStatus: null
            },

            showMuted: false,
            lee: false,
            browser: useCheckBrowser().name.toLowerCase(),
        }

        this.screenShareRef = React.createRef()
    }

    async componentDidMount() {
        window.addEventListener("beforeunload", this.onBeforeUnload.bind(this))

        this.handleKeyPress = this.handleKeyPress.bind(this)

        const { match } = this.props
        const { params: { roomCode, userType } } = match
        this.setState({ roomCode, urlUserType: userType })

        try {
            await this.handleRoomRedirect(roomCode)
        } catch (error) {
            return
        }

        if (userType === "onsite" || userType === "offsite") {
            setTimeout(() => {
                // kinda hacky but it works
                // wait for state to update with room info before starting call
                this.setLocationType(userType)
            }, 200)
        }
        else if (userType && userType.length > 0) {
            window.location.href = window.location.origin + `/room/${roomCode}`
        }

        // add listener for main mic switching and audio/video muting
        window.addEventListener("keydown", this.handleKeyPress)

    }

    async componentWillUnmount() { }

    static getDerivedStateFromProps(nextProps, prevState) {
        var shouldUpdateUser = !isEqual(nextProps.user, prevState.user)
        var shouldUpdateRoom = !isEqual(nextProps.currentRoom, prevState.currentRoom)
        var shouldUpdateNotification = !isEqual(nextProps.notifications, prevState.notifications)
        var shouldUpdateCall = !isEqual(nextProps.call, prevState.call)

        if (shouldUpdateUser) {
            return { user: nextProps.user }
        } else if (shouldUpdateNotification) {
            return { notifications: nextProps.notifications }
        } else if (shouldUpdateRoom) {
            return { currentRoom: nextProps.currentRoom }
        } else if (shouldUpdateCall) {
            return { call: nextProps.call }
        } else {
            return null
        }
    }

    async componentDidUpdate(_prevProps, prevState) {
        if (!isEqual(this.props.user, prevState.user)) {
            if (!prevState.user) {
                await this.handleRoomRedirect(this.state.roomCode)
            }
            this.setState({ user: this.props.user })
        }

        if (!isEqual(this.props.currentRoom, prevState.currentRoom)) {
            const room = this.props.currentRoom
            if (room.id.length > 0) this.updateStateWithRoom(room)
            if (prevState.currentRoom && prevState.currentRoom.id.length === 0 && prevState.call.callState !== "ENDED") {
                // create conference here if new room and call hasn't previously ended
                this.createConf()
            }
        }

        if (!isEqual(this.props.notifications, prevState.notifications)) {
            if (!isEqual(this.props.notifications.appNotifications, prevState.notifications.appNotifications)) {
                const { appNotifications } = this.props.notifications
                if (appNotifications.length > 0) this.dispatchNotifications()
            }

            this.setState({ notifications: this.props.notifications })
        }

        const call = this.props.call
        if (!isEqual(call, prevState.call)) {
            if (call) this.updateStateWithCallSession(prevState.call, call)
        }

    }

    onBeforeUnload(e) {
        this.leave()
    }

    dispatchNotifications() {
        const { notifications: { appNotifications } } = this.state

        appNotifications.forEach((v) => {
            if (v.type === "error") {
                toast.error(v.message);
            } else if (v.type === "warn") {
                toast.warn(v.message);
            } else if (v.type === "success") {
                toast.success(v.message);
            }
        })
    }

    updateStateWithCallSession(prevCall, call) {

        const publisher = call.publisher
        const screenSharePublisher = call.screenSharePublisher // [UserModel] array
        const screenShareSubscriber = call.screenShareSubscriber // [UserModel] array

        if (this.screenShareRef.current) {
            if ((screenShareSubscriber || screenSharePublisher) && !this.screenShareRef.current.firstChuld) {
                if (screenShareSubscriber) {
                    this.screenShareRef.current.appendChild(screenShareSubscriber)
                    this.screenShareRef.current.classList.remove("d-none")
                } else if (screenSharePublisher && this.screenShareRef.current.childElementCount === 0) {
                    const pubView = this.renderPublisherView()

                    this.screenShareRef.current.appendChild(pubView)
                    this.screenShareRef.current.classList.remove("d-none")
                }

            } else {
                this.removeScreenShareChildren()
                this.screenShareRef.current.classList.add("d-none")
            }
        }

        if (prevCall.publisher != call.publisher && call.publisher !== null) {
            this.setState({ showMuted: true })
            setTimeout(() => {
                this.setState({ showMuted: false })
            }, 4000)
        }

        this.setState({
            call,
            publisher,
            screenSharePublisher,
            screenShareSubscriber,
        })
    }

    // room helpers

    async handleRoomRedirect(roomCode) {
        // validate room code
        return new Promise(async (resolve, reject) => {

            const { user } = this.props
            if (!user) {
                reject("Invalid user")
                return
            }

            // get first matching code from database request
            const roomSnap = await database.ref(`/rooms/${roomCode}`).once("value")

            if (!roomSnap.exists()) {
                this.setState({ error: "ROOM_DOES_NOT_EXIST" }, () => {
                    reject('ROOM_DOES_NOT_EXIST')
                })
                return
            }

            // check here
            let currentRoom = roomSnap.val()
            
            // Only active users
            let isRoomFull = false
            if (currentRoom.who) {
                const who = Object.values(currentRoom.who)
                const activeUsers = who.filter((v) => v.type.length > 0)
                isRoomFull = activeUsers.length > 32
            }

            if (isRoomFull) {
                // display error and return
                this.setState({ error: "ROOM_FULL" }, () => {
                    reject('ROOM_FULL')
                })
                return
            }

            // room exists, continue
            this.startListenerForRoom(roomCode)
            this.addUserToRoom(roomCode)
            // success
            resolve()
        })

    }

    updateStateWithRoom(room) {
        const currentRoom = room
        this.setState({ currentRoom })
    }

    startListenerForRoom(id) {
        const { dispatch } = this.props
        dispatch(listenToRoom(id))
    }

    addUserToRoom(roomCode) {
        const user = this.props.user.info
        var email = user.email
        if (!user.email && user.providerData) email = user.providerData[0].email

        const userInfo = {
            uid: user.uid,
            name: user.displayName,
            email: email,
            type: "",
        }

        database.ref(`/rooms/${roomCode}/who/${user.uid}`).set(userInfo)
        database.ref(`/rooms/${roomCode}/callSession/callInfo/userLog/${user.uid}/callEvents`).push({ type: 'joined', timestamp: Date.now() })
    }

    leaveRoom() {
        const { history } = this.props
        history.push(`/space`)
    }

    logUserOut() {
        let loggingOut = window.confirm("Are you sure you want to log out?")

        if (!loggingOut) {
            return
        } else {
            const { dispatch } = this.props

            this.leave()

            dispatch(detachListener(this.state.user.info.uid))
            dispatch(logoutUser())
        }
    }


    leave(home = true) {
        const { user, dispatch } = this.props
        let userId = user.info.uid

        dispatch(leaveSession(userId, home))
    }

    handleManualChoosing() {

        clearTimeout(this.autoJoinTimeout)
        this.props.dispatch({
            type: "UPDATE_PREDICTED_LOCATION",
            payload: ""
        })

    }

    handleWaitingRoomOrJoin() {

        const { call: { predictedLocationType }, browser } = this.state

        console.log("predictedLocationType", predictedLocationType)
        
        let isChrome = browser === "chrome"
        let didPredict = false
        if (predictedLocationType === "onsite" && isChrome) {
            // set timeout for animation here
            // then join onsite 
            // this timeout can be cancelled by the user incase predicted location is WRONG
            didPredict = true
            this.autoJoinTimeout = setTimeout(() => {
                this.setLocationType("onsite")
            }, 4000)
        }

        this.setState({ choseDevices: true, didPredict: didPredict })
    }

    /* Call Helpers */

    async createConf() {
        const { currentRoom, user } = this.state
        const { dispatch } = this.props

        const userId = user.info.uid
        let userName = user.info.displayName
        let userPhotoURL = user.info.photoURL
        let extraData = {
            photoURL: userPhotoURL,
            userName,
            userId,
        }

        dispatch(createConference(extraData, currentRoom.id))
        
    }

    async setLocationType(type) {
        const { currentRoom, user, browser } = this.state
        const { dispatch } = this.props

        if (type === "onsite" && browser !== "chrome") {
            this.setState({ showUnsupportedBrowser: true })
            return
        }

        const userId = user.info.uid

        // do internal database stuff here
        database.ref(`/rooms/${currentRoom.id}/who/${userId}/type`).set(type)
        database.ref(`/rooms/${currentRoom.id}/callSession/callInfo/userLog/${userId}/type`).set(type)
        dispatch(startUserTypeListener(userId, currentRoom.id))

        // set type in API here
        dispatch(setType(type))
        dispatch(setListenersAndStartCall())
    }

    handleKeyPress(event) {
        if (event.target.className === 'new-message') {
            return;
        }

        switch (event.keyCode) {
            case 9:
                event.preventDefault()
                break
            case 77:
                this.toggleAudio_()
                break
            case 86:
                this.toggleVideo_()
                break
            case 192:
                this.toggleDebug()
            default:
                break
        }
    }

    /* Misc Helpers */

    peen(pinoType, context, error) {
        const { user } = this.state

        const uid = user.info.uid
        const browser = useCheckBrowser()
        const os = useGetOs()

        const log = {
            browser,
            os,
            error,
            uid,
            context
        }

        if (pinoType === 'error') {
            pino.error(log)
        }

    }

    pushURL(url) {
        const { history } = this.props
        history.push(url)
    }

    toggleAudioList() {
        const { videoList, audioList, outputList } = this.state

        let newState = {}
        if (videoList) { newState["videoList"] = false }
        if (outputList) { newState["outputList"] = false }

        newState["audioList"] = !audioList

        this.setState(newState)
    }

    toggleVideoList() {
        const { videoList, audioList, outputList } = this.state

        let newState = {}
        if (audioList) { newState["audioList"] = false }
        if (outputList) { newState["outputList"] = false }

        newState["videoList"] = !videoList

        this.setState(newState)
    }

    toggleOutputList() {
        const { videoList, audioList, outputList } = this.state

        let newState = {}
        if (audioList) { newState["audioList"] = false }
        if (videoList) { newState["videoList"] = false }

        newState["outputList"] = !outputList

        this.setState(newState)
    }

    toggleAudio_() {
        const { dispatch } = this.props
        dispatch(toggleAudio())
    }

    toggleVideo_() {
        const { dispatch } = this.props
        dispatch(toggleVideo())
    }

    toggleScreenShare_() {
        const { dispatch } = this.props
        dispatch(toggleScreenShare())
    }

    toggleChat() {
        this.setState({ chat: !this.state.chat })
    }

    toggleSettingsModal() {
        this.setState({ isShowingSettings: !this.state.isShowingSettings })
    }

    toggleDebug() {
        // this function will be used for any debug stuff
        // called by pressing ` on keyboard
        const { dispatch } = this.props
        dispatch(toggleSpike())

        // just following base of internal spike toggle
        // maybe do it properly from callback in future
        // but tbh not worth it
        this.setState({ lee: !this.state.lee })
    }

    toggleNoiseSupression(state) {
        const { dispatch } = this.props
        dispatch(debugAudioState(state))
    }

    goHome() {
        const { currentRoom } = this.state
        const { dispatch } = this.props

        dispatch(detachRoomListener(currentRoom.id))
        this.leave()
    }

    async finishTutorial() {
        const { user } = this.state
        const uid = user.info.uid
        await database.ref(`/users/${uid}/account_info/has_visited`).set(true)
    }

    renderPermissionsHelper() {

        const { call: { permissionStatus } } = this.state
        
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                width: '30%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#E6EAEB',
                border: 'none',
            },
            overlay: {
                backgroundColor: 'rgba(0,0,0,0.5)'
            }
        };

        if (!permissionStatus) return
        if (permissionStatus.state === "success") return
        
        let details = null

        if (permissionStatus.error.reason === "VIDEO_PERMISSION_DENIED") {
            details = (
                <div className="perm-detail-container">
                    <img className="img-fluid perm-image" src={require('../assets/chrome-enabled.png')}/>
                    <h3>Camera Not Found</h3>
                    <p>Please connect an available camera or check your browser/system settings for camera permissions.</p>
                    <button onClick={() => this.leave(false)} className="base-button purple-btn mt-5"><p>Try Again</p></button>
                </div>
            )
        } else if (permissionStatus.error.reason === "AUDIO_PERMISSION_DENIED") {
            details = (
                <div className="perm-detail-container">
                    <img className="img-fluid perm-image" src={require('../assets/chrome-enabled.png')}/>
                    <h3>Microphone Not Found</h3>
                    <p>Please connect an available microphone or check your browser/system settings for microphone permissions.</p>
                    <button onClick={() => this.leave(false)} className="base-button purple-btn mt-5"><p>Try Again</p></button>
                </div>
            )
        } else if (permissionStatus.error.reason === "PERMISSION_DENIED") {
            details = (
                <div className="perm-detail-container">
                    <img className="img-fluid perm-image" src={require('../assets/chrome-enabled.png')}/>
                    <h3>Camera/Microphone Not Found</h3>
                    <p>{permissionStatus.error.rawStr}</p>
                    <button onClick={() => this.leave(false)} className="base-button purple-btn mt-5"><p>Try Again</p></button>
                </div>
            )
        } else {
            details = (
                <div>
                    <h3>Error getting devices</h3>
                    <p>{permissionStatus.error.rawStr}</p>
                </div>
            )
        }

        return (
            <Modal
                style={customStyles}
                isOpen={true}
                onRequestClose={() => null}
            >
                <div className="modal-container">
                    {details}
                </div>
            </Modal>
        )
    }

    renderNoConnection() {

        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                width: '50%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#102C2E',
                border: 'none',
            },
            overlay: {
                backgroundColor: 'rgba(0,0,0,0.5)'
            }
        };
        
        return (
            <Modal
                style={customStyles}
                isOpen={true}
                onRequestClose={() => null}
            >
                <div>
                    <p className="text-center mb-3">Lost connection, retrying in 5 seconds.</p>
                </div>
            </Modal>
        )

    }

    renderChatView() {
        const { chat, roomCode, user } = this.state

        const name = user.info.displayName
        const uid = user.info.uid

        return (
            <div className={`abs-side-view cool-box-shadow ${!chat ? "hide-side" : "slide-in"}`}>
                <Chat type={'rooms'} id={roomCode} displayName={name} uid={uid} isShowing={chat} toggleChat={this.toggleChat.bind(this)} />
            </div>
        )
    }

    renderProfileView() {
        const { isShowingSettings } = this.state

        return (
            <div className={`abs-side-view cool-box-shadow ${!isShowingSettings ? "hide-side" : "slide-in"}`}>
                <Profile toggleProfile={this.toggleSettingsModal.bind(this)} logUserOut={this.logUserOut.bind(this)} />
            </div>
        )

    }

    renderOnsiteThumbs() {
        return <OnsiteThumbs />
    }

    renderAudioList() {
        const { audioList , browser } = this.state

        return (
            <div className={`abs-dropdown-view abs-audio-input-dropdown ${!audioList ? "hide-dropdown" : ""}`}>
                <Dropdown type={"audio"} browser={browser} closeDropdown={this.toggleAudioList.bind(this)} audioToggleCallback={this.toggleNoiseSupression.bind(this)} />
            </div>
        )

    }

    renderVideoList() {
        const { videoList } = this.state

        return (
            <div className={`abs-dropdown-view abs-video-input-dropdown ${!videoList ? "hide-dropdown" : ""}`}>
                <Dropdown type={"video"} closeDropdown={this.toggleVideoList.bind(this)} />
            </div>
        )

    }

    renderOutputList() {
        const { outputList } = this.state

        return (
            <div className={`abs-dropdown-view abs-audio-output-dropdown ${!outputList ? "hide-dropdown" : ""}`}>
                <Dropdown type={"output"} closeDropdown={this.toggleOutputList.bind(this)} />
            </div>
        )

    }

    renderMutedSign() {
        const { currentRoom: { settings }, showMuted } = this.state
        if (settings && settings.muteParticipantsOnEntry && showMuted) {
            return (
                <div className="muted-bar muted-hover-active">
                    <h2>You are muted</h2>
                </div>
            )
        }

        return null
    }

    renderOnboardingViews() {

        const { choseDevices, showUnsupportedBrowser, didPredict, call: { userLocationType, isInActiveSession } } = this.state

        if (isInActiveSession) return null
        
        let isChoosingDevices = userLocationType.length === 0 && !choseDevices && !showUnsupportedBrowser
        let isAutoJoining = userLocationType.length === 0 && choseDevices && didPredict
        let isChoosingType = !isChoosingDevices
        
        // check for device selection first!
        if (isChoosingDevices) return this.renderDeviceSelection()

        // check for predicted location view loading here
        if (isAutoJoining) return this.renderAutoJoiningView()

        // then render type selection
        if (isChoosingType) return this.renderChoosingView()

    }

    renderDeviceSelection() {
        const { choseDevices, browser, showUnsupportedBrowser, call: { userLocationType, isInActiveSession } } = this.state

        if (isInActiveSession) return null

        if (userLocationType.length === 0 && !choseDevices && !showUnsupportedBrowser) return (
            <div className="type-choosing-container">
                <DeviceSelection browser={browser} mobile={useMobile()} callback={() => this.handleWaitingRoomOrJoin()} />
            </div>
        )
    }

    renderAutoJoiningView() {

        const { currentRoom: { who } } = this.state
        
        let additionalStr = ""
        const onsiteActive = Object.values(who).filter(w => w.type === "onsite").map(({ name }) => name)
        if (onsiteActive.length > 0) additionalStr = ` with ${onsiteActive[0]} and others.`

        return (
            <div className="type-choosing-container">
                <div className="auto-location-view">
                    <div className="pace">
                        <div className="pace-progress">
                        </div>
                    </div>
                    <h5>Configuring Echo Canceller...</h5>
                    <p>We've detected that you're {this.state.call.predictedLocationType}{additionalStr}</p>
                    <div onClick={() => this.handleManualChoosing()} className="base-button underline-btn"><p>Wrong? Choose Manually</p></div>
                </div>
            </div>
        )
    }

    renderChoosingView() {
        const { call: { isInActiveSession } } = this.state

        if (isInActiveSession) return null

        let mobileView = (
            <div className="type-choosing-container">
                <div className="hero-text-container">
                    <h1 className="hero_text">Where Are You Calling From?</h1>
                </div>
                <div className="type-choosing-view-mobile">
                    <button className="type-choosing-button offsite-choosing" id="offSiteBtn" onClick={this.setLocationType.bind(this, "offsite")}>
                        <div className="type-choosing-text-container">
                            <h5>Calling Alone</h5>
                            <p>Choose this if you're calling remotely.</p>
                        </div>
                    </button>
                    <button className="type-choosing-button onsite-choosing" id="onSiteBtn" onClick={this.setLocationType.bind(this, "onsite")}>
                        <div className="type-choosing-text-container">
                            <h5>Calling With Others</h5>
                            <div className="badge-holder">
                                <div className='hybrid-badge'>BETA</div>
                            </div>
                            <p>Choose this if you're in a room with other people.</p>
                        </div>
                    </button>
                </div>
            </div>
        )

        if (useMobile()) return mobileView

        return (
            <div className="type-choosing-container">
                <div className="hero-text-container">
                    <h1 className="hero_text">Where Are You Calling From?</h1>
                </div>
                <div className="type-choosing-view">
                    <button className="type-choosing-button offsite-choosing" id="offSiteBtn" onClick={this.setLocationType.bind(this, "offsite")}>
                        <div className="type-choosing-text-container">
                            <h5>Calling Remotely</h5>
                            <p>Choose this if you're calling by yourself</p>
                        </div>
                        <img
                            src={require("../assets/offsite.png")}
                            alt="offsite"
                        />
                    </button>
                    <button className="type-choosing-button onsite-choosing" id="onSiteBtn" onClick={this.setLocationType.bind(this, "onsite")}>
                        <div className="type-choosing-text-container">
                            <h5>Calling Onsite</h5>
                            <div className="badge-holder">
                                <div className='hybrid-badge'>BETA</div>
                            </div>
                            <p>Choose this if you're in a room with other people.</p>
                        </div>
                        <img
                            src={require("../assets/onsite.png")}
                            alt="onsite"
                        />
                    </button>
                </div>
            </div>
        )
    }

    removeScreenShareChildren() {
        const screenShareView = document.getElementById("screen-share-view")
        if (!screenShareView) return

        while (screenShareView.firstChild) {
            screenShareView.removeChild(screenShareView.firstChild)
        }
    }

    renderPublisherView() {
        const screenBase = document.createElement("div")
        screenBase.className = "d-flex h-100 w-100 flex-column align-items-center justify-content-center"

        const text = document.createElement("h4")
        text.innerText = "You Are Screensharing"
        text.className = "text-center text-white mb-4"

        const btn = document.createElement("button")
        btn.innerHTML = "Stop Screensharing"
        btn.className = "green-btn base-button hover-btn"
        btn.onclick = this.toggleScreenShare_.bind(this)

        screenBase.appendChild(text)
        screenBase.appendChild(btn)

        return screenBase
    }

    renderMobile() {

        const mobileStyles = {
            content: {
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                width: '100%',
                height: '100%',
                // marginRight: '-50%',
                // transform: 'translate(-50%, -50%)',
                backgroundColor: '#E6EAEB',
                border: 'none',
                inset: 0,
            },
            overlay: {
                backgroundColor: 'rgba(0,0,0,0.5)'
            }

        }

        if (!useMobile()) return

        return (
            <Modal
                style={mobileStyles}
                isOpen={true}
                onRequestClose={() => null}
            >
                <div className="browser-modal-content">
                    <img src={phonePath} className="m-3 mb-5"/>
                    <h2 className="text-center mb-0">Unsupported Device</h2>
                    <h4 className="text-center mt-3">Between is only support on Chrome/Firefox desktop at the moment. <br/><br/> Please join through a laptop or desktop for the best experience.</h4>
                </div>
            </Modal>
        )
    }

    renderBrowser() {
        const { browser } = this.state

        const isChrome = browser.toLowerCase().includes('chrome')
        if (isChrome) return

        const mobileStyles = {
            content: {
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                width: '100%',
                height: '100%',
                // marginRight: '-50%',
                // transform: 'translate(-50%, -50%)',
                backgroundColor: '#E6EAEB',
                border: 'none',
                inset: 0,
            },
            overlay: {
                backgroundColor: 'rgba(0,0,0,0.5)'
            }

        }

        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                width: '50%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#E6EAEB',
                border: 'none',
            },
            overlay: {
                backgroundColor: 'rgba(0,0,0,0.5)'
            }
        };

        return (
            <Modal
                style={useMobile() ? mobileStyles : customStyles}
                isOpen={true}
                onRequestClose={() => null}
            >
                <div className="browser-modal-content">
                    <img alt="chrome" className="m-3 mb-5" width={"100px"} src={"https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/480px-Google_Chrome_icon_%28February_2022%29.svg.png"}/>
                    <h2 className="text-center mb-0">Unsupported Browser</h2>
                    <p className="text-center mb-3">Onsite features are best experienced on Chrome at the moment, support for other browsers is coming soon!</p>
                    <button className="purple-btn base-button hover-btn" onClick={() => this.setState({ showUnsupportedBrowser: false })}><p>Okay</p></button>
                </div>
            </Modal>
        )
    }

    renderRoomInfo() {
        const {
            chat,
            isShowingSettings,
            roomCode,
            notifications,
            audioList,
            videoList,
            call: { isInActiveSession, isConnected, userLocationType, inOfficeUsers },
            showUnsupportedBrowser,
            showUnsupportedDeviceType,
            lee
        } = this.state

        const { hasUnreadMessages } = notifications

        // seperate component views to load
        const chatView = this.renderChatView()
        const profileView = this.renderProfileView()
        const audioDropdown = this.renderAudioList()
        const videoDropdown = this.renderVideoList()
        const outputDropdown = this.renderOutputList()

        let screenShareView = <div ref={this.screenShareRef} id='screen-share-view' className='screen-share-view d-none' />

        return (
            <>
                <ToastContainer 
                    position={"bottom-left"}
                    autoClose={5000}
                    closeOnClick={true}
                    pauseOnHover={true}
                    draggable={true}
                    progress={undefined}
                    pauseOnFocusLoss={true}
                    theme={"light"}
                    bodyClassName={"between-toast-body"}
                />
                <div className={`container-grid ${inOfficeUsers.length > 0 ? 'container-grid-onsite' : ''} main-wrapper-room-page`}>
                    {profileView}
                    {chatView}
                    {outputDropdown}
                    {audioDropdown}
                    {videoDropdown}
                    <div className="room-main-view">
                        {isInActiveSession ? <VideoGrid browser={this.state.browser}/> : null}
                        {isInActiveSession ? screenShareView : null}
                        
                        {this.renderOnboardingViews()}
                        {this.renderMutedSign()}
                        
                        {!isInActiveSession && userLocationType.length > 0 ? <LoadingAnimation video={true} /> : null}

                        {showUnsupportedBrowser ? this.renderBrowser() : true}
                        {showUnsupportedDeviceType ? this.renderMobile() : null}
                        <DebugView show={lee} />
                    </div>
                    {this.renderOnsiteThumbs()}
                    <Toolbar
                        // data
                        roomId={roomCode}
                        // bools
                        isInRoom={true}
                        chat={chat}
                        settings={isShowingSettings}
                        hasUnreadMessages={hasUnreadMessages}
                        audioList={audioList}
                        videoList={videoList}

                        // functions
                        toggleChat={this.toggleChat.bind(this)}
                        toggleSettingsModal={this.toggleSettingsModal.bind(this)}
                        toggleAudioList={this.toggleAudioList.bind(this)}
                        toggleVideoList={this.toggleVideoList.bind(this)}
                        toggleOutputList={this.toggleOutputList.bind(this)}
                        goHome={this.goHome.bind(this)}
                    />
                    {!isConnected && isInActiveSession ? this.renderNoConnection() : null}
                    {this.renderPermissionsHelper()}
                </div>
            </>
        )
    }

    renderError() {
        const { error } = this.state

        let mainText = "Error!"
        let subText = error

        if (error === "ROOM_DOES_NOT_EXIST") {
            mainText = "Room Does Not Exist!"
            subText = "There was an error loading into the room. If you are the creator of this room we recommend refreshing. If not please double check that the link or code is valid."
        } else if (error === "ROOM_FULL") {
            mainText = "Room Full!"
            subText = "The room you are trying to enter has reached it's capacity, please try again or let the host know!"
        }

        return (
            <div className="main-wrapper not-found-error">
                <div className="not-found-container">
                    <h2 className="text-center mt-0 error-message">{mainText}</h2>
                    <p className="text-center mt-0 error-message">{subText}</p>
                    <div className="d-flex flex-row align-items-center justify-content-center" style={{ gap: '2rem' }}>
                        <button className="green-btn base-button" onClick={() => this.pushURL("/space")}>
                            <p>Go Home</p>
                        </button>
                        <button className="green-btn base-button" onClick={() => window.location.reload()}>
                            <p>Refresh Page</p>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { currentRoom, error } = this.state
        
        if (error) return this.renderError()
        else if (currentRoom && currentRoom.id.length > 0) {
            return this.renderRoomInfo()
        } else {
            return (
                <div className="main-wrapper main-bg align-items-center justify-content-center">
                    <h5 className="text-center light-text">Loading...</h5>
                </div>
            )
        }
    }

}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        notifications: state.notify,
        currentRoom: state.room,
        call: state.call,
    }
}

export default withRouter(connect(mapStateToProps)(EphemeralRoomPage))