import firebase from "../../firebase"

var database = firebase.database()

export const CLEAR_ROOM = "CLEAR_ROOM"
export const UPDATE_ROOM = "UPDATE_ROOM"
export const UPDATE_WHO = "UPDATE_WHO"
export const UPDATE_CALL_SESSION = "UPDATE_CALL_SESSION"
export const UPDATE_HOST = "UPDATE_HOST"
export const UPDATE_ROOM_SETTINGS = "UPDATE_ROOM_SETTINGS"

const clearRoom = () => {
    return {
        type: CLEAR_ROOM,
    }
}

const updateState = (type, payload) => {
    return {
        type: type,
        payload: payload,
    }
}

export function listenToRoom(id) {
    return async (dispatch) => {
        var baseRoomRef = database.ref(`/rooms/${id}`)

        const baseRoomSnap = await baseRoomRef.once("value")
        const baseRoom = baseRoomSnap.val()

        dispatch(updateState(UPDATE_ROOM, baseRoom))

        // listen for who
        baseRoomRef.child("who").on("value", (whoSnap) => {
            dispatch(updateState(UPDATE_WHO, whoSnap.val()))
        })

        baseRoomRef.child("settings").on("value", (settingsSnap) => {
            if (settingsSnap.exists()) dispatch(updateState(UPDATE_ROOM_SETTINGS, settingsSnap.val()))
        })

        // listen for host
        baseRoomRef.child("host").on("value", (hostSnap) => {
            dispatch(updateState(UPDATE_HOST, hostSnap.val()))
        })
    }
}

export const detachRoomListener = (id) => (dispatch) => {
    var baseRoomRef = database.ref(`/groups/${id}`)
    baseRoomRef.child(`/who`).off("value")
    baseRoomRef.child(`/settings`).off("value")
    baseRoomRef.child(`/host`).off("value")
    dispatch(clearRoom())
}

export const updateMuteSetting = (newState) => (_, getState) => {
    const roomId = getState().room.id
    database.ref(`/rooms/${roomId}/settings/muteParticipantsOnEntry`).set(newState)
}