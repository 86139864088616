import React from "react";

import { Route, Switch, Router, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createBrowserHistory } from 'history';

import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Login";

import { startTrackingSession, fetchUser, subToNotification } from "./redux/actions";
import LandingPage from "./components/LandingPage";
import EphemeralRoomPage from "./components/EphemeralRoomPage";

const history = createBrowserHistory({
    forceRefresh: false
})

function App(props: object) {
    const { isAuthenticated, isVerifying, isTrackingUser, user, dispatch }: any = props;

    if (!isVerifying && isAuthenticated && !isTrackingUser && user) {
        dispatch(startTrackingSession(user.info.uid));
        dispatch(fetchUser(user.info.uid));
        dispatch(subToNotification())
    }
    return (
        <Router history={history}>
            <Switch>
                <ProtectedRoute
                    exact
                    path="/space/"
                    component={LandingPage}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                />
                <ProtectedRoute
                    // exact
                    path="/room/:roomCode/:userType?"
                    component={EphemeralRoomPage}
                    isAuthenticated={isAuthenticated}
                    isVerifying={isVerifying}
                />
                <Route exact path="/" component={Login} />
            </Switch>
        </Router>
    );
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isVerifying: state.auth.isVerifying,
        isTrackingUser: state.auth.isTrackingUser,
        user: state.auth.user,
    };
}
export default withRouter(connect(mapStateToProps)(App));
