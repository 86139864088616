import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import VideoCell from "./VideoCell"

function VideoGrid(props: any) {

    // default options
    const _dish = document.getElementById("Dish")
    const margin = 20
    const aspectRatio = { width: 16, height: 9 }

    // state
    const [videoWidth, setVideoWidth] = useState(0)
    const [videoHeight, setVideoHeight] = useState(0)
    const [subscribers, setSubscribers] = useState([])
    const [publisher, setPublisher] = useState(null)
    const [connectionQuality, setConnectionQuality] = useState(null)
    const [smallCells, setSmallCells] = useState(false)
    const [shouldUpdateDimensions, setShouldUpdateDimensions] = useState(false)

    // props
    // get call data from props
    useEffect(() => {
        
        // calculate only when updates are made to subscribers or publisher
        let shouldCalculate = false
        if (props.call.publisher && !publisher) { shouldCalculate = true }
        if (props.call.subscribers.length !== subscribers.length) { shouldCalculate = true }

        setSubscribers(props.call.subscribers)
        setPublisher(props.call.publisher)

        let num = props.call.subscribers.length
        if (props.call.publisher) num += 1
        if (shouldCalculate) calculate(num)
    }, [props.call.subscribers, props.call.publisher])

    useEffect(() => {
        setConnectionQuality(props.call.connectionQuality)
    }, [props.call.connectionQuality])

    useEffect(() => {
        if (shouldUpdateDimensions) { updateVideoDimensions() }
    }, [shouldUpdateDimensions])

    const calculate = (numVideos: number) => {

        if (!_dish) return
        
        // if (props.browser === 'safari') {
        //     // for safari make it forced grid of squares
        //     setVideoWidth(_dish.offsetHeight * 0.5)
        //     setVideoHeight(_dish.offsetHeight * 0.5)
        //     setShouldUpdateDimensions(true)
        //     return
        // }
        
        const containerWidth = _dish.offsetWidth;
        const containerHeight = _dish.offsetHeight;
        const numColumns = Math.ceil(Math.sqrt(numVideos * aspectRatio.width / aspectRatio.height));
        const numRows = Math.ceil(numVideos / numColumns);
        
        // Calculate video width based on container width and number of columns
        let videoWidth = Math.floor((containerWidth - (numColumns + 1) * margin) / numColumns); // 10px margin

        // Calculate video height based on the aspect ratio
        let videoHeight = Math.floor(videoWidth * (aspectRatio.height / aspectRatio.width));

        // Check if calculated video height fits within container height
        const calcVideoHeight = videoHeight * numRows + (numRows + 1) * margin;
        if (calcVideoHeight > containerHeight) {
            // Recalculate dimensions based on container height
            videoHeight = Math.floor((containerHeight - (numRows + 1) * margin) / numRows); // 10px margin
            videoWidth = Math.floor(videoHeight * (aspectRatio.width / aspectRatio.height));
        }

        if (videoWidth <= 250) setSmallCells(true)
        else setSmallCells(false)

        setVideoHeight(videoHeight)
        setVideoWidth(videoWidth)
        setShouldUpdateDimensions(true)

    }

    const updateVideoDimensions = () => {
        if (!_dish) return

        const numVideos = _dish.children.length

        for (let s = 0; s < numVideos; s++) {
            const element = _dish.children[s] as HTMLElement;

            // calculate dimensions
            element.style.width =  `${videoWidth}px`
            element.style.height = `${videoHeight}px`
        }

        setShouldUpdateDimensions(false)
    }

    const renderVideoView = () => {

        const { call: { isInActiveSession, remoteUsers, userLocationType }, user } = props

        if (!isInActiveSession) return

        // content to render
        const content = []

        // data for self cell
        const selfUid = user.info.uid
        const selfName = user.info.displayName

        const selfCell = (
            <VideoCell
                key={selfUid}
                video={null}
                uid={selfUid}
                cell={''}
                name={selfName}
                opacity={1}
                type={userLocationType}
                isMainMic={false}
                connectionQuality={connectionQuality}
                isSelf={true}
                customLabel={null}
                setCustomLabel={null}
                isEditingCustomLabel={null}
                smallCells={smallCells}
            />
        )
        content.push(selfCell)

        // start getting data for all subscribers
        subscribers.forEach((subscriber) => {

            const uid = subscriber.getUserId()
            const extraData = JSON.parse(subscriber.getStreamManager().data.extraData)

            const name = extraData ? extraData.userName : ''
            const type = remoteUsers.includes(uid) ? "offsite" : "onsite"

            content.push(<VideoCell
                key={uid}
                video={null}
                uid={uid}
                cell={''}
                name={name}
                opacity={1}
                type={type}
                userLocationType={userLocationType}
                isMainMic={false}
                isSelf={false}
                customLabel={null}
                needsTutorial={!user.account_info.has_visited}
                isAudioActive={subscriber.isAudioActive()}
                isVideoActive={subscriber.isVideoActive()}
                smallCells={smallCells}

                onClick={null}
                finishTutorial={null}
            />)
        })

        return content
    }

    return (<div id="Dish" className="Dish">{renderVideoView()}</div>)

}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        call: { 
            publisher: state.call.publisher,
            subscribers: state.call.subscribers,
            connectionQuality: state.call.connectionQuality,
            isInActiveSession: state.call.isInActiveSession, 
            remoteUsers: state.call.remoteUsers, 
            userLocationType: state.call.userLocationType
        }
    }
}

export default connect(mapStateToProps)(VideoGrid)