import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import 'firebase/compat/database';

const env = window.location.hostname

let config = {}

if (env === 'localhost' || env === 'between-dev.web.app') {
    config = {
        apiKey: "AIzaSyDEx8uK_DgBjfO_M_MISyPks3xUM6wIBCc",
        authDomain: "between-dev.firebaseapp.com",
        databaseURL: "https://between-dev-default-rtdb.firebaseio.com",
        projectId: "between-dev",
        storageBucket: "between-dev.appspot.com",
        messagingSenderId: "931717214545",
        appId: "1:931717214545:web:d077fa70c221ae8d5b2370",
        measurementId: "G-FQFBMW9Y4C"
    };
} else if (env === 'in.between.co') {
    config = {
        apiKey: "AIzaSyBWUipRB6i6YbxDOk9L-fTl4D6iYii185g",
        authDomain: "between-87295.firebaseapp.com",
        databaseURL: "https://between-87295-default-rtdb.firebaseio.com",
        projectId: "between-87295",
        storageBucket: "between-87295.appspot.com",
        messagingSenderId: "508176178355",
        appId: "1:508176178355:web:99e890398e6aadc60624c6",
        measurementId: "G-H9B1B4H2NV"
    };
}

config = {
    apiKey: "AIzaSyDEx8uK_DgBjfO_M_MISyPks3xUM6wIBCc",
    authDomain: "between-dev.firebaseapp.com",
    databaseURL: "https://between-dev-default-rtdb.firebaseio.com",
    projectId: "between-dev",
    storageBucket: "between-dev.appspot.com",
    messagingSenderId: "931717214545",
    appId: "1:931717214545:web:d077fa70c221ae8d5b2370",
    measurementId: "G-FQFBMW9Y4C"
};

firebase.initializeApp(config)
export default firebase