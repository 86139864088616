import * as React from "react"
import { connect } from "react-redux"
import ReactSwitch from "react-switch";
const Switch = ReactSwitch as any;

import { checkPath } from "../assets/icons/helper"

import { setNewCurrentAudioDevice, setNewCurrentVideoDevice, setNewCurrentOutputDevice } from "../redux/actions"

import "../assets/css/Dropdown.css"

type Device = {
    deviceId: string
    label: string
    kind: "audioInput" | "videoInput"
}

type DeviceInfo = {
    audioDevices: Device[]
    videoDevices: Device[]
    outputDevices: Device[]
    currentAudioDevice: string
    currentVideoDevice: string
    currentOutputDevice: string
    userLocationType: string
}

type DropdownProps = {
    deviceInfo: DeviceInfo
    type: "audio" | "video" | "output"
    browser: string
    closeDropdown: () => void
    audioToggleCallback: (noiseToggle: boolean) => void
    dispatch: (something) => void
}

type DropdownState = {
    noiseToggle?: boolean
}

class Dropdown extends React.Component<DropdownProps, DropdownState> {
    state: DropdownState = {
        noiseToggle: false,
    }

    onChangeList(e: React.FormEvent<HTMLSelectElement>) {
        const newDeviceId = e.currentTarget.value
        this.setNewDevice(newDeviceId)
        this.props.closeDropdown()
    }

    setNewDevice(deviceId: string) {
        const { type, dispatch } = this.props

        if (type === "audio") {
            dispatch(setNewCurrentAudioDevice(deviceId))
        } else if (type === "video") {
            dispatch(setNewCurrentVideoDevice(deviceId))
        } else if (type === "output") {
            dispatch(setNewCurrentOutputDevice(deviceId))
        }
    }

    handleChange = (noiseToggle: boolean) => {
        this.setState({ noiseToggle })
        this.props.audioToggleCallback(noiseToggle)
    }

    renderAudioToggle() {
        
        const { type, deviceInfo: { userLocationType }, browser } = this.props
        if (type !== "audio" || userLocationType === "onsite" || browser !== "chrome") return null

        return (
            <>
                <div className="dropdown-item dropdown-audio-toggle">
                    <div>
                        <p>Noise Supression</p>
                        <Switch onChange={this.handleChange} checked={this.state.noiseToggle} checkedIcon={false} uncheckedIcon={false} width={40} height={20} handleDiameter={15} onHandleColor={"#D9FF00"} onColor={"#6369D1"} />
                    </div>
                </div>
            </>
        )

    }

    render() {
        const {
            deviceInfo: { audioDevices, videoDevices, outputDevices, currentAudioDevice, currentVideoDevice, currentOutputDevice, userLocationType },
            type,
        } = this.props

        let data = []
        let currentDevice = ""

        if (type === "audio") {
            data = audioDevices
            currentDevice = currentAudioDevice
        } else if (type === "video") {
            data = videoDevices
            currentDevice = currentVideoDevice
        } else if (type === "output") {
            data = outputDevices
            currentDevice = currentOutputDevice
        }

        return (
            <>
                <div className="dropdown-container">
                    {this.renderAudioToggle()}
                    {data.map((device: Device) => {
                        const isSelected = currentDevice === device.deviceId
                        const selectedIcon = isSelected ? <img src={checkPath} /> : null

                        return (
                            <button
                                key={device.deviceId}
                                className={`dropdown-item`}
                                value={device.deviceId}
                                onClick={this.onChangeList.bind(this)}
                            >
                                <div>
                                    <p>{device.label}</p>
                                    {selectedIcon}
                                </div>
                            </button>
                        )
                    })}
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        deviceInfo: state.call,
    }
}

const connector = connect(mapStateToProps)
export default connector(Dropdown)
