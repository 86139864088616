import React, { useEffect, useState } from "react"
import LoadingAnimation from "./LoadingAnimation"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import "../assets/css/LandingPage.css"
import "../assets/css/RoomPage.css"
import { microPath, microslashredfillPath } from "../assets/icons/helper"

const VideoCell = (props) => {

    /**
     * video
     * uid
     * cell
     * name
     * opacity
     * index
     * type
     * isSelf
     * customLabel
     * setCustomLabel
     * smallCells
     */

    // refs
    const cellRef = React.createRef()

    // states
    const [type, setType] = useState('')
    const [isSelf, setIsSelf] = useState(false)
    const [customLabel, setCustomLabel] = useState('')
    const [mainMic, setMainMic] = useState(false)
    const [showConnectionMessage, setShowConnectionMessage] = useState(false)
    const [connectionQuality, setConnectionQuality] = useState(null)
    const [loading, setLoading] = useState(true)

    const [isSelfAudioActive, setIsSelfAudioActive]  = useState(true)
    const [isAudioActive, setIsAudioActive]  = useState(true)
    const [isVideoActive, setIsVideoActive]  = useState(true)

    // effects
    useEffect(() => {
        // check loading state on initial load
        // check if video exists in child element of ${props.uid}_video-content

        if (cellRef.current) {
            const interval = setInterval(() => {
                let child = document.getElementById(`${props.uid}_video-content`).children[0]
                if (child) {
                    startVideo(child)
                    clearInterval(interval)
                }
            }, 300)
        }
    }, [])
    
    useEffect(() => {
        setType(props.type)
    }, [props.type])

    useEffect(() => {
        setIsSelf(props.isSelf)
    }, [props.isSelf])

    useEffect(() => {
        setCustomLabel(props.customLabel)
    }, [props.customLabel])

    useEffect(() => {
        setMainMic(props.isMainMic)
    }, [props.isMainMic])

    useEffect(() => {
        setIsAudioActive(props.isAudioActive)
    }, [props.isAudioActive])

    useEffect(() => {
        setIsSelfAudioActive(props.isSelfAudioActive)
    }, [props.isSelfAudioActive])

    useEffect(() => {
        setIsVideoActive(props.isVideoActive)
    }, [props.isVideoActive])
   
    useEffect(() => {
        setConnectionQuality(props.connectionQuality)
    }, [props.connectionQuality])

    const startVideo = (child) => {
        child.play().then(() => { 
            setLoading(false)
        }).catch((e) => { 
            console.log('error', e)
        })
    }

    // ui renderers
    const renderGridHover = () => {

        if (isSelf) return null

        const hoverName = props.name

        // handle rest of content here
        let content = (<><p>{hoverName}</p></>)

        return (
            <div className={`cell-hover-view ${type}-cell-hover-view`}>
                {content}
            </div>
        )
    }

    const renderConnection = () => {

        let popoverView = showConnectionMessage ? (
            <div id="connection-hover" className="connection-hover">
                <p>{connectionQuality.message}</p>
            </div>
        ) : null

        let type = connectionQuality ? connectionQuality.type === "CONNECTION_GOOD" ? "-good" : "-bad" : "-good"

        return (
            <div onMouseOver={() => setShowConnectionMessage(true)} onMouseOut={() => setShowConnectionMessage(false)} className="connection-badge">
                {popoverView}
                <div className={`connection-circle connection-circle${type}`}/> 
            </div>
        )
    }


    // label renderers
    const typeText = customLabel ? customLabel : type.charAt(0).toUpperCase() + type.slice(1)
    const typeLabel = (
        <div id={`${props.uid}_label`} className={`${type}-label type-label-container scale-anim`}>
            {mainMic ? <img src={microPath}/> : null}
            <p>{typeText}</p>
        </div>
    )

    const isActive = isSelf ? isSelfAudioActive : isAudioActive
    const mediaState = (
        <div className="media-state-icons">
            <img src={isActive ? microPath : microslashredfillPath} />
        </div>
    )

    const smallMediaState = (
        <div className="small-media-icon">
            <div className="media-state-icons">
                <img src={isAudioActive ? microPath : microslashredfillPath} />
            </div>
        </div>
    )

    const nameView = (
        <div id={`${props.uid}_name`} className={`cell-name-label scale-anim`}>
            {mediaState}
            {isSelf && type === "onsite" ? renderConnection() : null }
            <p>{props.name}</p>
        </div>
    )

    return (

        <div
            id={props.uid}
            className={`video-cell ${mainMic ? 'main-mic-border' : ''}`}
            onClick={props.onClick ? () => props.onClick() : null}
        >
            <div ref={cellRef} id={`${props.uid}_video-content`} className="video-content" />
            {
                !props.smallCells ? (
                    <div className="labels-content">
                        {nameView}
                        {typeLabel}
                    </div>
                ) : null
            }
            {props.smallCells && !isActive ? smallMediaState : null}
            {props.smallCells ? renderGridHover() : null}
            {loading ? <div className="cell-loading-container"><LoadingAnimation video={true} /></div> : null}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isSelfAudioActive: state.call.audio,
    }
}

export default withRouter(connect(mapStateToProps)(VideoCell))