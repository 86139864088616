import {
    UPDATE_ROOM,
    UPDATE_WHO,
    CLEAR_ROOM,
    UPDATE_HOST,
    UPDATE_ROOM_SETTINGS
} from "../actions/room"

const initialState = {
    id: "",
    name: "",
    host: "",
    who: {},
    settings: {
        muteParticipantsOnEntry: false,
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_ROOM:
            return initialState
        case UPDATE_ROOM:
            return {
                ...state,
                ...action.payload
            }
        case UPDATE_WHO:
            return {
                ...state,
                who: action.payload,
            }
        case UPDATE_HOST:
            return {
                ...state,
                host: action.payload,
            }
        case UPDATE_ROOM_SETTINGS:
            return {
                ...state,
                settings: action.payload,
            }
        default:
            return state
    }
}
