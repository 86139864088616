import {
    CLEAR_CALL,
    UPDATE_AUDIO_DEVICES,
    UPDATE_VIDEO_DEVICES,
    UPDATE_OUTPUT_DEVICES,
    UPDATE_CURRENT_AUDIO,
    UPDATE_CURRENT_VIDEO,
    UPDATE_CURRENT_OUTPUT,
    STARTING_CALL,
    UPDATE_SESSION_HELPER,
    UPDATE_PUBLISHER,
    UPDATE_ACTIVE_SESSION,
    UPDATE_AUDIO,
    UPDATE_VIDEO,
    ADD_SUBSCRIBER,
    REMOVE_SUBSCRIBER,
    UPDATE_REMOTE,
    UPDATE_IN_OFFICE,
    REMOVE_IN_OFFICE,
    UPDATE_SCREEN_SHARE_PUBLISHER,
    UPDATE_SCREEN_SHARE_SUBSCRIBER,
    UPDATE_USER_LOCATION_TYPE,
    UPDATE_IN_OFFICE_MAIN_MIC,
    UPDATE_IN_OFFICE_MIC_LIST,
    UPDATE_IN_OFFICE_LOCATIONS,
    IS_CONNECTED,
    SUBSCRIBER_VIDEO_TOGGLE,
    SUBSCRIBER_AUDIO_TOGGLE,
    SUBSCRIBER_SCREENSHARE_TOGGLE,
    UPDATE_CALL_STATE,
    UPDATE_PERMISSION_STATUS,
    UPDATE_NETWORK_QUALITY,
    UPDATE_PREDICTED_LOCATION,
} from "../actions/call";

const initialState = {
    callState: "INITIAL", // INITIAL, IN_CALL, ENDED
    isInActiveSession: false,
    startingCall: false,
    sessionHelper: null,
    publisher: null,
    subscribers: [],
    inOfficeUsers: [],
    remoteUsers: [],
    audio: true,
    video: true,
    screenShareSubscriber: null,
    screenSharePublisher: null,
    userLocationType: "",
    predictedLocationType: "",

    audioDevices: [],
    videoDevices: [],
    outputDevices: [],
    currentAudioDevice: "",
    currentVideoDevice: "",
    currentOutputDevice: "",

    inOfficeMainMic: '',
    inOfficeLocations: [],
    inOfficeMics: [],

    isConnected: true,

    permissionStatus: null,

    connectionQuality: {
        type: "CONNECTION_GOOD",
        message: "Your network quality is great! Your speaker and mic are enabled.",
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_CALL:
            return {...initialState, callState: state.callState}
        case STARTING_CALL:
            return {
                ...state,
                startingCall: action.payload,
            }
        case UPDATE_SESSION_HELPER:
            return {
                ...state,
                sessionHelper: action.payload,
            }
        case UPDATE_PUBLISHER:
            return {
                ...state,
                publisher: action.payload,
            }
        case UPDATE_ACTIVE_SESSION:
            return {
                ...state,
                isInActiveSession: action.payload,
            }
        case UPDATE_AUDIO:
            return {
                ...state,
                audio: action.payload,
            }
        case UPDATE_VIDEO:
            return {
                ...state,
                video: action.payload,
            }
        case ADD_SUBSCRIBER:
            return {
                ...state,
                subscribers: [...state.subscribers, action.payload],
            }
        case REMOVE_SUBSCRIBER:
            return {
                ...state,
                subscribers: state.subscribers.filter((item) => item.getUserId() !== action.payload),
            }
        case UPDATE_REMOTE:
            return {
                ...state,
                remoteUsers: action.payload,
            }
        case UPDATE_IN_OFFICE:
            return {
                ...state,
                inOfficeUsers: [...state.inOfficeUsers, action.payload],
            }
        case REMOVE_IN_OFFICE:
            return {
                ...state,
                inOfficeUsers: state.inOfficeUsers.filter((item) => item.peerId !== action.payload),
            }
        case UPDATE_SCREEN_SHARE_PUBLISHER:
            return {
                ...state,
                screenSharePublisher: action.payload,
            }
        case UPDATE_SCREEN_SHARE_SUBSCRIBER:
            return {
                ...state,
                screenShareSubscriber: action.payload,
            }
        case UPDATE_USER_LOCATION_TYPE:
            return {
                ...state,
                userLocationType: action.payload,
            }
        case UPDATE_AUDIO_DEVICES:
            return {
                ...state,
                audioDevices: action.payload,
            };
        case UPDATE_VIDEO_DEVICES:
            return {
                ...state,
                videoDevices: action.payload,
            };
        case UPDATE_OUTPUT_DEVICES:
            return {
                ...state,
                outputDevices: action.payload,
            };
        case UPDATE_CURRENT_AUDIO:
            return {
                ...state,
                currentAudioDevice: action.payload,
            };
        case UPDATE_CURRENT_VIDEO:
            return {
                ...state,
                currentVideoDevice: action.payload,
            };
        case UPDATE_CURRENT_OUTPUT:
            return {
                ...state,
                currentOutputDevice: action.payload,
            };
        case UPDATE_IN_OFFICE_MAIN_MIC:
            return {
                ...state,
                inOfficeMainMic: action.payload
            }
        case IS_CONNECTED:
            return {
                ...state,
                isConnected: action.payload
            }
        case SUBSCRIBER_VIDEO_TOGGLE:
            const videoIndex = state.subscribers.findIndex((item) => item.getUserId() === action.payload.id)
            if (videoIndex === -1) { return state }

            state.subscribers[videoIndex].setVideoActive(action.payload.active)

            return {
                ...state
            }
        case SUBSCRIBER_AUDIO_TOGGLE:
            const audioIndex = state.subscribers.findIndex((item) => item.getUserId() === action.payload.id)
            if (audioIndex === -1) { return state }

            state.subscribers[audioIndex].setAudioActive(action.payload.active)

            return {
                ...state
            }
        case SUBSCRIBER_SCREENSHARE_TOGGLE:
            const shareIndex = state.subscribers.findIndex((item) => item.getUserId() === action.payload.id)
            if (shareIndex === -1) { return state }

            state.subscribers[shareIndex].setScreenShareActive(action.payload.active)

            return {
                ...state
            }
        case UPDATE_IN_OFFICE_LOCATIONS:
            return {
                ...state,
                inOfficeLocations: action.payload
            }
        case UPDATE_IN_OFFICE_MIC_LIST:
            return {
                ...state,
                inOfficeMics: action.payload
            }
        case UPDATE_CALL_STATE:
            return {
                ...state,
                callState: action.payload
            }
        case UPDATE_PERMISSION_STATUS:
            return {
                ...state,
                permissionStatus: action.payload
            }
        case UPDATE_NETWORK_QUALITY:
            return {
                ...state,
                connectionQuality: action.payload
            }
        case UPDATE_PREDICTED_LOCATION:
            return {
                ...state,
                predictedLocationType: action.payload
            }
        default:
            return state;
    }
};

function generateNumGrid() {
    let grid = []

    for (let i = 0; i < 40; i++) { grid.push(i) }

    return grid
}