import React, { Component } from "react"

import ReactGA from "react-ga"

import LoadingAnimation from './LoadingAnimation'
import Profile from "./Profile"

import firebase from "../firebase"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import useCheckBrowser from "./hooks/useCheckBrowser"
import useGetOs from './hooks/useGetOs'

import { logoutUser, detachListener } from "../redux/actions"
import { apiCall } from "../apiCall"
import pino from './pino'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { configblackPath, videoblackPath } from "../assets/icons/helper"

var database = firebase.database()
var isEqual = require("lodash.isequal")

class LandingPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showContinueView: false,
            companyDetails: props.companyDetails,

            user: {},
            roomId: null,

            isShowingSettings: false,
            creatingRoom: false,
            
            appNotifications: [],
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        var shouldUpdateUser = !isEqual(nextProps.user, prevState.user)
        var shouldUpdateNotify = !isEqual(nextProps.notify.appNotifications, prevState.appNotifications)

        if (shouldUpdateUser) {
            return { user: nextProps.user }
        } else if (shouldUpdateNotify) {
            return { appNotifications: nextProps.notify.appNotifications }
        } else {
            return null
        }
    }

    componentDidMount() {
        // this.test()
    }

    async componentDidUpdate(_prevProps, prevState) {
        if (!isEqual(this.props.user, prevState.user)) {
            this.setState({ user: this.props.user })
        }

        if (!isEqual(this.props.notify.appNotifications, prevState.appNotifications)) {
            this.setState({ appNotifications: this.props.notify.appNotifications }, () => {
                const { appNotifications } = this.state

                if (appNotifications.length > 0) this.dispatchNotifications()
            })
        }
    }

    componentWillUnmount() {
        const { roomId } = this.state

        if (roomId) database.ref(`/rooms/${roomId}`).off("value")
    }

    dispatchNotifications() {
        const { appNotifications } = this.state

        appNotifications.forEach((v) => {
            if (v.type === "error") {
                toast.error(v.message, {
                    position: "bottom-left",
                    autoClose: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else if (v.type === "warn") {
                toast.warn(v.message, {
                    position: "bottom-left",
                    autoClose: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })
    }

    // Lounge Helpers

    async createNewRoom() {
        const { user, creatingRoom } = this.state

        if (creatingRoom) return

        this.setState({ creatingRoom: true })

        try {
            const requestBody = {
                roomName: 'Ephemeral Room',
                userId: user.info.uid,
            }

            const resp = await fetch('https://turn.between.co/helper/createRoom', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            })

            if (resp.status === 200) {
                const response = await resp.json()
                const roomId = response.roomId
                ReactGA.event({
                    category: "Call",
                    action: `Created a new call`,
                })
                this.setState({ roomId }, () => {
                    this.startListenerForRoom()
                })
            } else if (resp.status === 400) {
                // ahh error, show modal with error and hope for the best
                const response = await resp.json()
                const errorMessage = response.message
                this.setState({ creatingRoom: false })
                alert("Error Creating Room: ", errorMessage)
            }
        } catch (error) {
            const uid = user.info.uid

            const browser = useCheckBrowser()
            const os = useGetOs()

            const log = {
                uid,
                browser,
                os,
                error,
                context: 'Error while creating room'
            }

            pino.error(log)
            this.setState({ creatingRoom: false })
        }
    }

    async startListenerForRoom() {
        const { roomId } = this.state
        database.ref(`/rooms/${roomId}`).on("value", (snap) => {
            if (snap.exists()) {
                this.pushURL(`/room/${roomId}`)
            }
        })
    }

    pushURL(url) {
        const { history } = this.props

        if (this.props.pushURL) {
            this.props.pushURL(url)
        } else {
            history.push(url)
        }
    }

    toggleSettingsModal() {
        this.setState({ isShowingSettings: !this.state.isShowingSettings })
    }

    // user helpers

    logUserOut() {
        let loggingOut = window.confirm("Are you sure you want to log out?")

        if (!loggingOut) {
            return
        } else {
            const { dispatch } = this.props

            dispatch(detachListener(this.state.user.info.uid))
            dispatch(logoutUser())
        }
    }

    // UI Helpers

    renderSettingsCog() {

        return (
            <div className="settings-cog-container" onClick={this.toggleSettingsModal.bind(this)}>
                <img src={configblackPath} />
            </div>
        )

    }

    renderProfileView() {
        const { isShowingSettings } = this.state

        return (
            <div className={`abs-side-view cool-box-shadow ${!isShowingSettings ? "hide-side" : "slide-in"}`} style={{ bottom: '0px', height: '100%' }}>
                <Profile toggleProfile={this.toggleSettingsModal.bind(this)} logUserOut={this.logUserOut.bind(this)} />
            </div>
        )

    }

    renderDefaultView() {
        const { user } = this.state

        if (!user) return null

        return (
            <>
                <div className="onboarding-header-container">
                    <h1 className="onboarding-header">Meet <span className="accent-text">Between!</span></h1>
                </div>

                <div className="d-flex flex-row w-75">
                    <div className="box-container">
                        <div
                            className="box"
                            onClick={this.createNewRoom.bind(this)}
                        >
                            <img src={videoblackPath} className="box-img" />
                            <h5 className="dark-text text-center mt-4 mb-0">Create A Call</h5>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    render() {
        const { creatingRoom } = this.state

        return (
            <div className="main-wrapper main-wrapper-landing-page">
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">
                        {this.renderDefaultView()}
                        {creatingRoom ? <div className='loading-container'><LoadingAnimation /></div> : null}
                        {this.renderSettingsCog()}
                        {this.renderProfileView()}
                        <ToastContainer />
                    </div>
                </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        notify: state.notify,
    }
}

export default withRouter(connect(mapStateToProps)(LandingPage))
